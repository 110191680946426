import { ReactComponent as IconChangeEmailSuccess } from "assets/icons/ic_change_email_success.svg";
import { useEffect, useState } from "react";
import OtpInput from "components/cotp";
import { changeEmail, verifyChangeEmail } from "services/profile";
import { requestOTP } from "services/auth";
import { toast } from "react-toastify";
import { CapitalizeFirstLetter } from "utils";

export default function ChangeEmail(props: {
  data;
  onSave: () => void;
  onCancel: () => void;
}) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isBtnVerifyDisabled, setBtnVerifyDisabled] = useState(true);

  const onChangeOTP = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    setBtnVerifyDisabled(otp.trim().length < 4);
  }, [otp]);

  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);

  const [isDisabledNext, setIsDisabledNext] = useState(true);

  const onSubmit = async () => {
    setIsLoading(true);
    changeEmail({
      email: email,
    })
      .then((res) => {
        requestOTP().then((res) => {
          setIsLoading(false);
          setShowState("otp");
          setCanResend(false);
          setTimeLeft(30);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onResend = async () => {
    setIsLoadingResend(true);
    requestOTP().then((res) => {
      setIsLoadingResend(false);
      setTimeLeft(30);
      setCanResend(false);
    });
  };

  const onVerify = async () => {
    setIsLoadingVerify(true);
    verifyChangeEmail({
      email: email,
      pin: otp,
    })
      .then((res) => {
        setIsLoadingVerify(false);
        setShowState("success");
      })
      .catch((err) => {
        setIsLoadingVerify(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onCancel = async () => {
    props.onCancel();
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const [showState, setShowState] = useState("");

  return (
    <div className="w-full m-8 bg-white rounded-lg ">
      {showState === "otp" ? (
        <div className="m-auto text-center py-8">
          <p className="text-lg font-semibold">Kode Verifikasi</p>
          <p className="text-sm text-text-grey mt-4">
            Masukkan kode 4 Digit untuk memverifikasi email Anda
          </p>
          <p className="text-sm text-text-grey">
            yang telah dikirim ke <b className="font-medium">{props.data}</b>
          </p>

          <div className="flex justify-center my-5">
            <OtpInput value={otp} valueLength={4} onChange={onChangeOTP} />
          </div>
          <div className="mt-6">
            {isLoadingResend ? (
              <div className="flex justify-center">
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              </div>
            ) : canResend ? (
              <div className="flex justify-center">
                <p className="text-sm text-text-grey">Tidak menerima kode?</p>
                <p
                  className="text-sm font-medium text-text-dark-blue ml-1 cursor-pointer"
                  onClick={() => {
                    onResend();
                  }}
                >
                  Kirim Ulang Kode
                </p>
              </div>
            ) : (
              <p className="text-sm text-text-grey">
                {"Harap tunggu " +
                  timeLeft +
                  " detik untuk mengirim ulang kode"}
              </p>
            )}
          </div>
          <div className="w-full flex justify-center mt-4">
            <button
              type="button"
              disabled={isBtnVerifyDisabled}
              className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-80 h-12 disabled:bg-slate-300"
              onClick={() => onVerify()}
            >
              <strong>
                {isLoadingVerify ? "Memverifikasi..." : "Verifikasi"}
              </strong>
              {isLoadingVerify ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
          </div>
        </div>
      ) : showState === "success" ? (
        <div className="m-auto text-center py-8">
          <div className="flex justify-center">
            <IconChangeEmailSuccess />
          </div>
          <p className="text-lg font-medium">
            Email Anda telah berhasil
            <br />
            diperbarui!
          </p>
        </div>
      ) : (
        <div className="m-auto text-center py-8">
          <p className="text-lg font-semibold">Ganti Email</p>
          <p className="text-sm text-text-black mt-4">
            {"Email Anda saat ini adalah " + props.data + "."}
          </p>
          <p className="text-sm text-text-black">
            Anda ingin memperbaruinya menjadi apa?
          </p>
          <p className="text-sm text-text-grey mt-6">Alamat Email</p>
          <input
            id="email"
            defaultValue={email}
            onChange={(val) => {
              setEmail(val.target.value);
              setIsDisabledNext(val.target.value === "");
            }}
            type="email"
            className=" w-80 mt-4 p-4 h-12 text-gray-900 rounded-full bg-white border border-neutral-300 focus:border-blue-200"
            placeholder="Alamat Email Anda"
          />
          <p className="text-xs text-text-grey font-medium mt-3">
            Kode verifikasi akan dikirimkan ke alamat
            <br />
            email lama Anda
          </p>
          <div className="grid grid-cols-2 gap-4 w-96 mt-6 mx-auto">
            <button
              type="button"
              className="text-sm font-medium text-text-black bg-white border border-slate-200 hover:bg-black/10 rounded-full h-12"
              onClick={() => onCancel()}
            >
              <p>Batal</p>
            </button>
            <button
              type="button"
              className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full h-12 disabled:bg-slate-300"
              onClick={() => onSubmit()}
              disabled={isDisabledNext}
            >
              <strong>{isLoading ? "Mengirim..." : "Kirim"}</strong>
              {isLoading ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
