import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { sessionTypes } from "constant/Session";

const utils = {
  InvalidLocationCode: "LOC---------",
  OnlineLocationCode: "",
};

interface ScheduleTimeSlot {
  format_calls: string;
  time_from: string;
  time_to: string;
  location_code: string[];
}
interface ScheduleDay {
  day: string;
  time: ScheduleTimeSlot[];
}

interface LocationRoomData {
  location_code: string;
  name: string;
  address: string;
  room_name: string;
  session_type: string; // format_calls
  status: string;
}

interface LocationDays {
  [day: string]: LocationRoomData[][];
}

export function ScheduleItem(props: {
  day: string;
  schedules: any[];
  locations: any[];
  showBorder: boolean;
  onRemoveSchedule: (index: number) => void;
  onAddSchedule: () => void;
  onChange: (index: number, value: any) => void;
  onChangeLocation: (index: number, formatCall: string) => void;
}) {
  return (
    <div
      className={`w-full text-center py-4 ${
        props.showBorder ? "border-b" : ""
      }`}
    >
      <p>{props.day}</p>
      {props.schedules?.map((val, i) => {
        const locationList: LocationRoomData[] = props.locations?.[i]
          ? props.locations[i] || []
          : [];
        return (
          <div key={i}>
            <div className="flex items-center justify-between">
              <div className="mt-2 grid grid-cols-12 items-center">
                <MobileTimePicker
                  ampm={false}
                  value={val.time_from}
                  minutesStep={15}
                  onChange={(newTime) =>
                    props.onChange(i, {
                      ...val,
                      time_from: newTime,
                    })
                  }
                  minTime={i > 0 ? props.schedules[i - 1].time_to : null}
                  className="col-span-2"
                />
                <div className="mx-2 col-span-1">ke</div>
                <MobileTimePicker
                  ampm={false}
                  value={val.time_to}
                  minutesStep={15}
                  onChange={(newTime) =>
                    props.onChange(i, {
                      ...val,
                      time_to: newTime,
                    })
                  }
                  minTime={val.time_from ?? null}
                  className="col-span-2"
                />
                {/*-- Session --*/}
                <Select
                  displayEmpty
                  multiple
                  value={val.format_calls ? val.format_calls.split(",") : []}
                  error={!(val.format_calls && val.format_calls.length)}
                  onChange={(event) => {
                    props.onChange(i, {
                      ...val,
                      format_calls:
                        typeof event.target.value === "string"
                          ? event.target.value
                          : event.target.value.join(","),
                      location_code: [],
                    });
                    props.onChangeLocation(
                      i,
                      typeof event.target.value === "string"
                        ? event.target.value
                        : event.target.value.join(",")
                    );
                  }}
                  renderValue={(selected: string[]) => {
                    if (selected.length === 0) {
                      return <p className="text-slate-400">Format Call</p>;
                    }

                    const vals = sessionTypes
                      .filter((item) => {
                        return selected.includes(item.key);
                      })
                      .map((val) => val.label);

                    return vals.join(", ");
                  }}
                  sx={{
                    borderRadius: "5px",
                    marginLeft: "16px",
                    textAlign: "start",
                  }}
                  className="col-span-3"
                >
                  {sessionTypes.map((item) => {
                    return (
                      <MenuItem key={item.key} value={item.key}>
                        <Checkbox
                          color="default"
                          checked={(val.format_calls ?? "")
                            .split(",")
                            .includes(item.key)}
                        />
                        <p>{item.label}</p>
                      </MenuItem>
                    );
                  })}
                </Select>
                {/*-- Location --*/}
                {val.format_calls?.includes("online") &&
                !val.format_calls?.includes("in_person") ? (
                  <div className="col-span-3 border border-solid border-black rounded-md ml-4 h-14 flex items-center justify-start pl-3">
                    <p>Online</p>
                  </div>
                ) : (
                  <Select
                    displayEmpty
                    value={
                      val.location_code && val.location_code.length
                        ? val.location_code[0]
                        : utils.InvalidLocationCode
                    }
                    onChange={(event) =>
                      props.onChange(i, {
                        ...val,
                        location_code:
                          event.target.value != utils.InvalidLocationCode
                            ? [event.target.value]
                            : [],
                      })
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      borderRadius: "5px",
                      marginLeft: "16px",
                      textAlign: "start",
                    }}
                    className="col-span-3"
                    error={!(val.location_code && val.location_code.length)}
                  >
                    <MenuItem value={utils.InvalidLocationCode}>
                      <p className="text-slate-400">Lokasi</p>
                    </MenuItem>
                    {locationList.map((_loc) => (
                      <MenuItem
                        key={_loc.location_code}
                        value={_loc.location_code}
                      >
                        <p>{_loc.name}</p>
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <div
                  className="col-span-1 px-2 cursor-pointer"
                  onClick={() => props.onRemoveSchedule(i)}
                >
                  x
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="flex gap-2 items-center justify-center cursor-pointer mt-4"
        onClick={() => props.onAddSchedule()}
      >
        <p className="text-sm font-medium text-brand-dark">Tambah Jam</p>
      </div>
    </div>
  );
}
