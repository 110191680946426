import { Dayjs } from "dayjs";
import config from "helpers/config";
import { getRoleCookies } from "helpers/localStorage";
import { Calendar } from "iconsax-react";
import moment from "moment";
import { Link } from "react-router-dom";

export function ListSession(props: { item: any; value: Dayjs }) {
  return (
    <Link
      to={`../session/${moment(props.value.toString()).format("yyyy-MM-DD")}/${
        props.item.code
      }`}
    >
      <div className="w-full py-3 cursor-pointer">
        <>
          {getRoleCookies() === "member" ? (
            <div className="w-12 float-left">
              <img
                src={config.AWS_URL_IMAGES + props.item.icon}
                className="w-10 h-10 rounded-full"
                alt=""
              />
            </div>
          ) : (
            <div className="flex items-center justify-center w-10 h-10 bg-custom-white mx-auto rounded-full float-left mr-2">
              <p className="text-[16px] font-medium">
                {props.item.title.substring(0, 1)}
              </p>
            </div>
          )}
        </>

        <div className="w-full">
          <div className="flex items-start justify-between mb-1">
            <p className="font-medium text-sm">{props.item.title}</p>
            <p className="font-medium text-xs min-w-[100px] text-end text-neutral-secondary">
              {props.item.status === "done"
                ? "Selesai"
                : props.item.status === "rescheduled"
                ? "Dijadwalkan Ulang"
                : props.item.status === "request_reschedule"
                ? "Dijadwalkan Ulang"
                : props.item.status === "cancelled_book"
                ? "Dibatalkan"
                : "Dipesan"}
            </p>
          </div>
          <div className="flex items-start">
            <Calendar size="18" color="#767E8C" />
            <p className="text-xs ml-1">
              {moment(props.item.created_date).format(
                "ddd, DD MMM YYYY [at] hh:mm A"
              )}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
