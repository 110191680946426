import api from "./api";

export const fetchSchedule = async (data: { lang: string }) => {
  const res = await api.get("/v1/specialist/schedule");
  return res.data;
};

export const postSchedule = async () => {
  const res = await api.post("/v1/specialist/schedule");
  return res.data;
};

export const putSchedule = async (data: { code: string }) => {
  const res = await api.put("/v1/specialist/schedule/" + data.code);
  return res.data;
};

export const putNoticePeriod = (params: {
  noticePeriod: number;
  psychologCode: string;
}) => {
  return api
    .put(`/v1/specialists/${params.psychologCode}/notice-period`, {
      notice_period: params.noticePeriod,
    })
    .then((val) => val.data);
};

export const checkCalendarToken = async (params: { memberCode: string }) => {
  return api.get(`/v1/calendar/sync/token`).then((val) => val.data);
};

export const refreshCalendarToken = async (params: { memberCode: string }) => {
  return api.get(`/v1/calendar/sync/refresh`).then((val) => val.data);
};

export const updateCalendarToken = async (params: {
  status: string;
  memberCode: string;
}) => {
  return api
    .put(`/v1/calendar/sync/status`, {
      status: params.status,
    })
    .then((val) => val.data);
};

export const fetchLocationByFormatCallV2 = async (params: {
  format_call: string;
}) => {
  return api
    .get("/v2/locations/codes", { params: params })
    .then((val) => val.data);
};

export const updateSchedule = async (params: {
  scheduleCode: string;
  dateRange: number;
  scheduleDay: any[];
  timezone: string;
  formatCall: string;
  location: string[];
}) => {
  return api
    .put(`/v1/specialist/schedule/${params.scheduleCode}`, {
      date_range: params.dateRange,
      schedule_day: params.scheduleDay,
      timezone: params.timezone,
      format_call: params.formatCall,
      location: params.location,
    })
    .then((val) => val.data);
};

export const updateTimezone = async (params: { timezone: string }) => {
  return api
    .put(`/v1/members/profile/timezone`, {
      timezone: params.timezone,
    })
    .then((val) => val.data);
};

export const updateSpecialistNoticePeriod = (params: {
  noticePeriod: number;
}) => {
  return api
    .put(`/v1/specialist/schedule/notice-period`, {
      notice_period: params.noticePeriod,
    })
    .then((val) => val.data);
};

export const getSchedulesV2 = async (params: {
  memberCode: string;
  status?: string;
  formatCall: string;
  locationCode: string;
  date: string;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule_v2`,
    {
      params: {
        status: params.status,
        format_call: params.formatCall,
        location_code: params.locationCode,
        date: params.date,
      },
    }
  );

  return resp.data;
};

export const getSchedule = async (params: {
  memberCode: string;
  scheduleDetailCode: string;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/${params.scheduleDetailCode}`
  );

  return resp.data;
};

export const getScheduleMonthlyV2 = async (params: {
  memberCode: string;
  formatCall: string;
  locationCode: string;
  year: number;
  month: number;
}) => {
  return api
    .get(`/v2/specialist/${params.memberCode}/schedule/monthly_v2`, {
      params: {
        format_call: params.formatCall,
        location_code: params.locationCode,
        year: params.year,
        month: params.month,
      },
    })
    .then((val) => val.data);
};

export const getScheduleByReservation = async (params: {
  reservationCode: string;
}) => {
  return api
    .get(
      `/v1/members/specialist/reservation/${params.reservationCode}/schedule`
    )
    .then((val) => val.data);
};

export const getAvailabilityFormatCall = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/format_call`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};

export const getAvailabilityLocationV2 = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/format_call/location_v2`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};
