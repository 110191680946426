import api from "./api";

export const uploadImage = async (file) => {
  const formData = new FormData();

  formData.append("uploadfile", file);

  const resp = await api.post("/v1/upload-image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const uploadFile = async (file) => {
  const formData = new FormData();

  formData.append("uploadfile", file);

  const resp = await api.post("/v1/upload-file", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const uploadAudio = async (file) => {
  const formData = new FormData();

  formData.append("uploadfile", file);

  const resp = await api.post("/v1/upload-audio", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Expect: "100-continue",
    },
  });
  return resp.data;
};
