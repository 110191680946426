import { ReactComponent as IconChangePasswordSuccess } from "assets/icons/ic_change_password_success.svg";
import { InputText } from "components/cinput";
import OtpInput from "components/cotp";
import ShowHidePassword from "components/showHidePassword";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as ImgForgotSuccess } from "assets/images/img_forgot_success.svg";
import {
  confirmNewPassword,
  reqForgotPassword,
  verifyPinForgotPassword,
} from "services/profile";
import { CapitalizeFirstLetter } from "utils";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isBtnVerifyDisabled, setBtnVerifyDisabled] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledChange, setIsDisabledChange] = useState(true);

  const onChangeOTP = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    setBtnVerifyDisabled(otp.trim().length < 4);
  }, [otp]);

  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);

  const onSendMeCode = async () => {
    setIsLoading(true);
    reqForgotPassword({
      email: email,
    })
      .then((res) => {
        setIsLoading(false);
        setShowState("otp");
        setCanResend(false);
        setTimeLeft(30);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onSubmitPassword = async () => {
    setIsLoadingPassword(true);
    confirmNewPassword({
      pin: otp,
      memberCode: memberCode,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    })
      .then((res) => {
        setIsLoadingPassword(false);
        setShowSuccess(true);
      })
      .catch((err) => {
        setIsLoadingPassword(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onResend = async () => {
    setIsLoadingResend(true);
    reqForgotPassword({
      email: email,
    })
      .then((res) => {
        setIsLoadingResend(false);
        setTimeLeft(30);
        setCanResend(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [memberCode, setMemberCode] = useState("");
  const onVerify = async () => {
    setIsLoadingVerify(true);
    verifyPinForgotPassword({
      email: email,
      pin: otp,
    })
      .then((res) => {
        setIsLoadingVerify(false);
        setShowState("password");
        setMemberCode(res.data.member_code);
      })
      .catch((err) => {
        setIsLoadingVerify(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const [showState, setShowState] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div className="max-h-screen lg:overflow-hidden ">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
        <div className="relative max-h-screen w-full md:w-full ">
          <img
            src="/images/bg_login.png"
            className="max-h-screen w-full object-cover object-center"
            alt=""
          />
          <img
            src="/images/logo_circle.png"
            className="absolute top-10 left-10 w-16 h-16"
            alt=""
          />
        </div>
        {showState === "otp" ? (
          <div className="bg-white px-20 py-40">
            <p className="text-2xl font-semibold">Verifikasi Email Anda</p>
            <p className="text-lg font-normal text-text-grey mt-2">
              Silakan masukkan 4 Digit kode yang dikirimkan ke
            </p>
            <b className="font-bold text-text-grey">{email}</b>

            <div className="flex justify-center my-10">
              <OtpInput value={otp} valueLength={4} onChange={onChangeOTP} />
            </div>
            <div className="mb-5">
              {isLoadingResend ? (
                <div className="flex justify-center">
                  <div
                    className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  ></div>
                </div>
              ) : canResend ? (
                <div className="flex justify-center">
                  <p className="text-sm text-text-grey">Tidak menerima kode?</p>
                  <p
                    className="text-sm font-medium text-text-dark-blue ml-1 cursor-pointer"
                    onClick={() => {
                      onResend();
                    }}
                  >
                    Kirim Ulang Kode
                  </p>
                </div>
              ) : (
                <div className="flex justify-center">
                  <p className="text-sm text-text-grey">
                    {"Harap tunggu " +
                      timeLeft +
                      " detik untuk mengirim ulang kode"}
                  </p>
                </div>
              )}
            </div>
            <button
              type="button"
              disabled={isBtnVerifyDisabled}
              className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-12 mt-4 disabled:bg-slate-300"
              onClick={() => onVerify()}
            >
              <p className="font-medium">
                {isLoadingVerify ? "Memverifikasi..." : "Verifikasi"}
              </p>
              {isLoadingVerify ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
            <button
              type="button"
              className="text-sm flex items-center justify-center font-medium text-black bg-white border border-gray-200 hover:text-text-dark-blue hover:border-text-dark-blue rounded-full h-12 w-full mt-20"
              onClick={() => navigate("/")}
            >
              <p>Kembali ke Halaman Masuk</p>
            </button>
          </div>
        ) : showState === "success" ? (
          <div className="m-auto text-center py-8">
            <div className="flex justify-center">
              <IconChangePasswordSuccess />
            </div>
            <p className="text-lg font-medium">
              Password Anda telah berhasil
              <br />
              diperbarui!
            </p>
          </div>
        ) : showState === "password" ? (
          <div className="bg-white px-20 py-40">
            <p className="text-2xl font-semibold">Buat Password Baru</p>
            <p className="text-lg font-normal text-text-grey mt-2 mb-5">
              Password baru Anda harus berbeda dengan password yang digunakan
              sebelumnya
            </p>

            <div className="w-full">
              <ShowHidePassword
                name={newPassword}
                defaultValue={newPassword}
                placeholder="Password Baru (8+ karakter)"
                onChange={(val) => {
                  setNewPassword(val);
                  setIsDisabledChange(val === "" || confirmPassword === "");
                }}
              ></ShowHidePassword>
              <div className="mt-4"></div>
              <ShowHidePassword
                name={confirmPassword}
                defaultValue={confirmPassword}
                placeholder="Konfirmasi Password Baru"
                onChange={(val) => {
                  setConfirmPassword(val);
                  setIsDisabledChange(val === "" || newPassword === "");
                }}
              ></ShowHidePassword>
              <button
                type="button"
                disabled={isDisabledChange}
                className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full h-12 w-full mt-6 disabled:bg-slate-300"
                onClick={() => onSubmitPassword()}
              >
                <p className="font-medium">
                  {isLoadingPassword ? "Mengirim..." : "Kirim"}
                </p>
                {isLoadingPassword ? (
                  <div
                    className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  ></div>
                ) : null}
              </button>
              <button
                type="button"
                className="text-sm flex items-center justify-center font-medium text-black bg-white border border-gray-200 hover:text-text-dark-blue hover:border-text-dark-blue rounded-full h-12 w-full mt-20"
                onClick={() => navigate("/")}
              >
                <p>Kembali ke Halaman Masuk</p>
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-white px-20 py-40">
            <p className="text-2xl font-semibold">Lupa Password</p>
            <p className="text-lg font-normal text-text-grey mt-2 mb-5">
              Silakan masukkan email yang Anda gunakan untuk masuk
            </p>
            <InputText
              placeholder="Alamat Email Anda"
              onChange={(val) => {
                setEmail(val);
                setIsDisabled(val === "");
              }}
              defaultValue={email}
              type="email"
              id="email"
            ></InputText>
            <button
              type="button"
              className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-12 mt-4 disabled:bg-slate-300"
              onClick={onSendMeCode}
              disabled={isDisabled}
            >
              <p className="font-medium">
                {isLoading ? "Mengirim..." : "Atur Ulang Password"}
              </p>
              {isLoading ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
            {/* <div className="flex items-center justify-center mt-4">
              <div className="font-normal text-sm text-text-grey">
                Sudah punya akun?{" "}
                <span
                  className="text-brand-dark cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  Log In Here
                </span>
              </div>
            </div> */}
            <button
              type="button"
              className="text-sm flex items-center justify-center font-medium text-black bg-white border border-gray-200 hover:text-text-dark-blue hover:border-text-dark-blue rounded-full h-12 w-full mt-20"
              onClick={() => navigate("/")}
            >
              <p>Kembali ke Halaman Masuk</p>
            </button>
          </div>
        )}
      </div>

      {showSuccess ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                setShowSuccess(false);
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[450px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <h3 className="text-lg font-semibold">Success</h3>
                  <p className="text-sm font-normal mt-3 text-text-grey">
                    You have successfully create new password
                  </p>
                  <ImgForgotSuccess className="my-6" />
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium bg-black text-white border border-gray-200 rounded-full h-12 w-full mt-4"
                    onClick={() => navigate("/")}
                  >
                    <p>Kembali ke Halaman Masuk</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ForgotPassword;
