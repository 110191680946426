import moment from "moment";

export const removeEmoji = (text?: string) => {
  if (!text) {
    return "";
  }

  return text
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    )
    .replace(/\s+/g, " ")
    .trim();
};

export const GetExtension = (str) => {
  return str.split("/").pop().split(".").pop();
};

export const CapitalizeFirstLetter = (str) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export function formatRupiah(amount) {
  return Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(amount);
}

export function getSessionType(text: string) {
  const IN_PERSON_CHILD = "in_person_child";
  const IN_PERSON_COUPLE = "in_person_couple";
  const IN_PERSON_FAMILY = "in_person_family";
  const IN_PERSON_INDIVIDUAL = "in_person_individual";
  const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
  const IN_PERSON_SI_THERAPY = "in_person_sensory_integration_therapy";
  const IN_PERSON_SPEECH_THERAPY = "in_person_speech_therapy";
  const ONLINE_CALL_INDIVIDUAL = "online_call_individual";
  const ONLINE_CALL_COUPLE = "online_call_couple";

  if (text === IN_PERSON_INDIVIDUAL) {
    return "Face to face (Individual)";
  } else if (text === IN_PERSON_COUPLE) {
    return "Face to face (Couple)";
  } else if (text === IN_PERSON_FAMILY) {
    return "Face to face (Family)";
  } else if (text === IN_PERSON_CHILD) {
    return "Face to face (Child)";
  } else if (text === IN_PERSON_HYPNOTHERAPY) {
    return "Face to face (Hypnotherapy)";
  } else if (text === IN_PERSON_SI_THERAPY) {
    return "Face to face (SI Therapy)";
  } else if (text === IN_PERSON_SPEECH_THERAPY) {
    return "Face to face (Speech Therapy)";
  } else if (text === ONLINE_CALL_INDIVIDUAL) {
    return "Online Call (Individual)";
  } else if (text === ONLINE_CALL_COUPLE) {
    return "Online Call (Couple)";
  } else {
    return "Face to face (Individual)";
  }
}

export function getSessionTypeShort(type: string) {
  if (type.includes("in_person")) {
    return "In-Person";
  } else {
    return "Online";
  }
}

export function getScheduleByDay(day: string, data: any) {
  if (data && data.schedule_day) {
    //console.log(JSON.stringify(data.schedule_day));
    var index = data.schedule_day.findIndex((_x) => _x.day === day);
    if (index > -1) {
      var days = "";
      for (const _day of data.schedule_day[index].time) {
        if (days === "") {
          days =
            moment(_day.time_from, "HH:mm:ss").format("HH:mm A") +
            " - " +
            moment(_day.time_to, "HH:mm:ss").format("HH:mm A");
        } else {
          days =
            days +
            "\n" +
            moment(_day.time_from, "HH:mm:ss").format("HH:mm A") +
            " - " +
            moment(_day.time_to, "HH:mm:ss").format("HH:mm A");
        }
      }
      return days;
    } else {
      return "-";
    }
  } else {
    return "-";
  }
}

function postMessageScrollTop() {
  if (!window.top) return;

  const message = JSON.stringify({
    type: "scroll_top",
  });
  window.top.postMessage(message, "*");
}

function validateRescheduleAndCancel(hour, days, rule) {
  if (days > 0) {
    return false;
  } else if (days === 0) {
    if (hour < rule) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

const utils = {
  postMessageScrollTop,
  validateRescheduleAndCancel,
};
export default utils;
