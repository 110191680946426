import React from "react";
import { ModalSimple } from "components/modal/modal_simple";
import { Box, DialogActions, DialogContent } from "@mui/material";
import config from "helpers/config";
import { getUserdata } from "helpers/localStorage";
import { toast } from "react-toastify";

export default function ModalReferSuccess(props: {
  isShow: boolean;
  toggle: () => void;
}) {
  return (
    <ModalSimple isShown={props.isShow} title="" hide={props.toggle}>
      <>
        <DialogContent>
          {/* CONTENT */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p className="text-base font-semibold">Lihat profil ku di Ohana:</p>
            <p className="text-base font-semibold text-text-dark-blue">
              {config.LANDING_URL}
            </p>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "4px 24px !important" }}>
          <button
            type="button"
            className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full h-12 mb-8 disabled:bg-slate-300 mx-auto w-[300px]"
            onClick={() => {
              navigator.clipboard.writeText(
                config.LANDING_URL +
                  "/booking/profile/" +
                  getUserdata().memberCode
              );
              toast.success("Tautan berhasil disalin");
            }}
          >
            <p>Salin Teks</p>
          </button>
        </DialogActions>
      </>
    </ModalSimple>
  );
}
