import { useEffect, useState } from "react";
import { ScheduleItem } from "components/ScheduleItem";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fetchLocationByFormatCallV2 } from "services/schedule";
import { CapitalizeFirstLetter } from "utils";
import { Loading } from "components/Loading";

interface ScheduleTimeSlot {
  format_calls: string,
  time_from: string,
  time_to: string,
  location_code: string[],
}
interface ScheduleDay  {
  day: string,
  time: ScheduleTimeSlot[],
}

interface LocationRoomData {
  location_code: string,
  name: string,
  address: string,
  room_name: string,
  session_type: string, // format_calls
  status: string,
}

interface LocationDays {
  [day: string]: LocationRoomData[][]
}

async function listLocationByFormatCallV2(format_call: string): Promise<LocationRoomData[]> {
  const format_calls = format_call.split(",").filter((x) => x.includes("in_person"));
  if (format_calls.length > 0) {
    return fetchLocationByFormatCallV2({
      format_call: format_calls.join(","),
    }).then((res) => {
      return res.data as LocationRoomData[];
    });
  }
  return [];
}

export default function SettingSchedule(props: {
  schedule;
  onSave: (data) => void;
}) {
  const [schedules, setSchedules] = useState<any>();

  const [locationSchedule, setLocationSchedule] = useState<any>();

  useEffect(() => {
    const scheduleDays = props.schedule ?? [];
    const schedule: any = {};

    initLocation();

    for (const item of scheduleDays) {
      const dayName = item.day as string;
      const times: any[] = [];

      for (const time of item.time) {
        const timeFroms = time.time_from.split(":");
        const timeTos = time.time_to.split(":");
        const timeFrom = new Date();
        timeFrom.setHours(Number(timeFroms[0]), Number(timeFroms[1]), 0, 0);

        const timeTo = new Date();
        timeTo.setHours(Number(timeTos[0]), Number(timeTos[1]), 0, 0);
        times.push({
          time_from: timeFrom,
          time_to: timeTo,
          format_calls: time.format_calls,
          location_code: time.location_code,
        });
      }

      schedule[dayName.toLocaleLowerCase()] = times;
    }

    setSchedules(schedule);

    // initLocation();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const initLocation = async () => {
    setIsLoading(true);
    const scheduleDays: ScheduleDay[] = props.schedule ?? [];
    const location: LocationDays = {};
    for (const item of scheduleDays) {
      const dayName = item.day as string;
      const times: LocationRoomData[][] = [];
      for (const [_i, time] of item.time.entries()) {
        times.push(await listLocationByFormatCallV2(time.format_calls));
      }

      location[dayName.toLocaleLowerCase()] = times;
    }

    setLocationSchedule(location);
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const scheduleDays: any[] = [];
    for (const key in schedules) {
      if (Object.prototype.hasOwnProperty.call(schedules, key)) {
        const items = schedules[key];
        const times: any[] = [];

        for (const item of items) {
          times.push({
            time_from: dayjs(item.time_from as Date).format("HH:mm"),
            time_to: dayjs(item.time_to as Date).format("HH:mm"),
            format_calls: item.format_calls,
            location_code: item.location_code,
          });
        }

        scheduleDays.push({
          day: CapitalizeFirstLetter(key),
          time: times,
        });
      }
    }

    props.onSave(scheduleDays);
  };

  const onAddSchedule = (day: string) => {
    if (!Array.isArray(schedules[day])) schedules[day] = [];
    let timeFrom: Date, timeTo: Date;
    if (schedules[day].length == 0) {
      timeFrom = new Date();
      timeFrom.setHours(8, 0, 0);

      timeTo = new Date();
      timeTo.setHours(16, 0, 0);
    } else {
      const lastTime = schedules[day].slice(-1).pop();
      const timeFroms = dayjs(lastTime.time_to as Date)
        .format("HH:mm")
        .split(":");
      const firstHour = Number(timeFroms[0].toString());
      const secondHour = firstHour + 3 > 24 ? 24 : firstHour + 3;

      timeFrom = new Date();
      timeFrom.setHours(firstHour, Number(timeFroms[1].toString()), 0, 0);

      timeTo = new Date();
      timeTo.setHours(secondHour, 0, 0, 0);
    }

    const times: any[] = schedules[day] ?? [];
    times.push({
      time_from: timeFrom,
      time_to: timeTo,
    });

    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onChangeSchedule = (day: string, index: number, newValue: any) => {
    const times: any[] = schedules[day] ?? [];
    if (times.length <= index) {
      return toast.error("invalid schedules. please restart!");
    }

    times[index] = newValue;
    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onRemoveSchedule = (day: string, index: number) => {
    let times: any[] = schedules[day] ?? [];
    if (times.length <= index) {
      return toast.error("invalid schedules. please restart!");
    }

    times.splice(index, 1);
    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onChangeScheduleLocation = async (
    day: string,
    index: number,
    format_call: string
  ) => {
    const location: LocationDays = locationSchedule;
    if (!location[day]) {
      location[day] = [];
    }
    // times
    if (location[day].length < index+1) {
      for (let i=location[day].length; i<index+1; i++)
        location[day].push([]);
    }
    setIsLoading(true);

    const newLocationSchedule: LocationDays = {
      ...location,
      [day]: [
        ...location[day].slice(0,index),
        await listLocationByFormatCallV2(format_call),
        ...location[day].slice(index+1),
      ],
    };
    setLocationSchedule(newLocationSchedule);
    setIsLoading(false);
  };

  const checkFormValid = () => {
    const isScheduleExist = Object.keys(schedules || {}).length > 0;

    // schedules  is not ScheduleDay, but similar, wtf!
    //console.log(schedules);

    const missingLocations = Object.keys(schedules || {}).filter((day) => {
      const scheduleTime = schedules[day] as ScheduleTimeSlot[];
      let hasInvalid = 0;
      let timebefore = "00:00";
      for (let timeslot of scheduleTime) {
        if (!timeslot.format_calls) {
          hasInvalid++; break;
        }
        if (timeslot.format_calls?.includes("in_person")) {
          if (!timeslot.location_code) {
            hasInvalid++; break;
          }
          if (timeslot.location_code?.length == 0) {
            hasInvalid++; break;
          }
        }
        if (timeslot.time_from < timebefore) {
          hasInvalid++; break;
        }
        if (timeslot.time_from > timeslot.time_to) {
          hasInvalid++; break;
        }
        timebefore = timeslot.time_to;
      }
      return hasInvalid > 0;
    });

    return isScheduleExist && missingLocations.length == 0;
  };

  const isEnabled = checkFormValid();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="w-full p-4 m-4 bg-white rounded-lg">
        <div className="text-center">
          <p className="text-xl font-semibold">Jadwal</p>
        </div>

        {isLoading ? (
          <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-white/60">
              <div className="absolute w-full my-6 mx-auto md:max-w-lg">
                <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                  <div className="px-10 py-6 w-full">
                    <Loading></Loading>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* Mondays */}
        <ScheduleItem
          day="Mondays"
          schedules={schedules ? schedules["monday"] : []}
          locations={locationSchedule ? locationSchedule["monday"] : []}
          showBorder={true}
          onAddSchedule={() => onAddSchedule("monday")}
          onRemoveSchedule={(index) => onRemoveSchedule("monday", index)}
          onChange={(index, newValue) =>
            onChangeSchedule("monday", index, newValue)
          }
          onChangeLocation={(index, formatCall) =>
            onChangeScheduleLocation("monday", index, formatCall)
          }
        ></ScheduleItem>

        {/* Tuesdays */}
        <ScheduleItem
          day="Tuesdays"
          schedules={schedules ? schedules["tuesday"] : []}
          locations={locationSchedule ? locationSchedule["tuesday"] : []}
          showBorder={true}
          onAddSchedule={() => onAddSchedule("tuesday")}
          onRemoveSchedule={(index) => onRemoveSchedule("tuesday", index)}
          onChange={(index, newValue) =>
            onChangeSchedule("tuesday", index, newValue)
          }
          onChangeLocation={(index, formatCall) =>
            onChangeScheduleLocation("tuesday", index, formatCall)
          }
        ></ScheduleItem>

        {/* Wednesdays */}
        <ScheduleItem
          day="Wednesdays"
          schedules={schedules ? schedules["wednesday"] : []}
          locations={locationSchedule ? locationSchedule["wednesday"] : []}
          showBorder={true}
          onAddSchedule={() => onAddSchedule("wednesday")}
          onRemoveSchedule={(index) => onRemoveSchedule("wednesday", index)}
          onChange={(index, newValue) =>
            onChangeSchedule("wednesday", index, newValue)
          }
          onChangeLocation={(index, formatCall) =>
            onChangeScheduleLocation("wednesday", index, formatCall)
          }
        ></ScheduleItem>

        {/* Thursdays */}
        <ScheduleItem
          day="Thursdays"
          schedules={schedules ? schedules["thursday"] : []}
          locations={locationSchedule ? locationSchedule["thursday"] : []}
          showBorder={true}
          onAddSchedule={() => onAddSchedule("thursday")}
          onRemoveSchedule={(index) => onRemoveSchedule("thursday", index)}
          onChange={(index, newValue) =>
            onChangeSchedule("thursday", index, newValue)
          }
          onChangeLocation={(index, formatCall) =>
            onChangeScheduleLocation("thursday", index, formatCall)
          }
        ></ScheduleItem>

        {/* Fridays */}
        <ScheduleItem
          day="Fridays"
          schedules={schedules ? schedules["friday"] : []}
          locations={locationSchedule ? locationSchedule["friday"] : []}
          showBorder={true}
          onAddSchedule={() => onAddSchedule("friday")}
          onRemoveSchedule={(index) => onRemoveSchedule("friday", index)}
          onChange={(index, newValue) =>
            onChangeSchedule("friday", index, newValue)
          }
          onChangeLocation={(index, formatCall) =>
            onChangeScheduleLocation("friday", index, formatCall)
          }
        ></ScheduleItem>

        {/* Saturdays */}
        <ScheduleItem
          day="Saturdays"
          schedules={schedules ? schedules["saturday"] : []}
          locations={locationSchedule ? locationSchedule["saturday"] : []}
          showBorder={true}
          onAddSchedule={() => onAddSchedule("saturday")}
          onRemoveSchedule={(index) => onRemoveSchedule("saturday", index)}
          onChange={(index, newValue) =>
            onChangeSchedule("saturday", index, newValue)
          }
          onChangeLocation={(index, formatCall) =>
            onChangeScheduleLocation("saturday", index, formatCall)
          }
        ></ScheduleItem>

        {/* Sundays */}
        <ScheduleItem
          day="Sundays"
          schedules={schedules ? schedules["sunday"] : []}
          locations={locationSchedule ? locationSchedule["sunday"] : []}
          showBorder={false}
          onAddSchedule={() => onAddSchedule("sunday")}
          onRemoveSchedule={(index) => onRemoveSchedule("sunday", index)}
          onChange={(index, newValue) =>
            onChangeSchedule("sunday", index, newValue)
          }
          onChangeLocation={(index, formatCall) =>
            onChangeScheduleLocation("sunday", index, formatCall)
          }
        ></ScheduleItem>

        <div className="w-full flex justify-center ">
          <button
            type="button"
            disabled={!isEnabled}
            className="text-white bg-black hover:bg-black/60 disabled:bg-black/40 rounded-full w-80 h-12"
            onClick={() => onSubmit()}
          >
            <strong>{"Simpan"}</strong>
          </button>
        </div>
      </div>
    </LocalizationProvider>
  );
}
