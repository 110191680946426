import { ReactComponent as IconCopy } from "assets/icons/ic_copy.svg";
import { ReactComponent as IconFile } from "assets/icons/ic_file.svg";
import { ReactComponent as IconStarFilled } from "assets/icons/ic_star_filled.svg";
import { ReactComponent as IconStarEmpty } from "assets/icons/ic_star_empty.svg";
import { ReactComponent as IconCircleFill } from "assets/icons/ic_check_circle_fill.svg";
import { ReactComponent as ImgRefundRequest } from "assets/images/img_refund_request.svg";
import { ReactComponent as IconCalendarGoogle } from "assets/icons/ic_calendar_google.svg";
import { ReactComponent as IconAttachment } from "assets/icons/ic_attachment.svg";
import { Divider } from "components/divider";
import { onContactUs, onJoinGMeet } from "helpers";
import config from "helpers/config";
import { ArrowRight2, Calendar, CloseCircle } from "iconsax-react";
import moment from "moment";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  addNoteFile,
  addReview,
  checkOvertime,
  checkReview,
  fetchNoteFile,
  getCancelReason,
  getSessionDetail,
  postCancel,
  putRequestReschedule,
  putReschedule,
  updateReservation,
  updateSessionStatus,
} from "services/session";
import utils, {
  CapitalizeFirstLetter,
  GetExtension,
  formatRupiah,
} from "utils";
import { ReactComponent as IconPhone } from "assets/icons/ic_phone.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkCurrentTime,
  checkDisabledReschedule,
  getTimezone,
} from "helpers/date";
import RescheduleSection from "./reschedule_section";
import { Loading } from "components/Loading";
import ApiCalendar from "react-google-calendar-api";
import Rating from "@mui/material/Rating/Rating";
import { getRoleCookies, getUserdata } from "helpers/localStorage";
import ModalOvertime from "./modal_overtime";
import { addOvertime, deleteOvertime, updateOvertime } from "services/overtime";
import { ModalDelete } from "components/modal/modal_simple";
import { uploadFile } from "services/upload";
import axios from "axios";
import ModalOvertimeLink from "./modal_overtime_link";

const configCalendar = {
  clientId: config.GOOGLE_CLIENT_ID,
  apiKey: config.GOOGLE_API_KEY,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const apiCalendar = new ApiCalendar(configCalendar);

interface SpecialistRate {
  price: number;
  fee: number;
  format_call: string;
}

interface MemberTimeZone {
  title: string;
  datetime: string;
  zone: string;
  offset: number;
}

interface PsychologOvertime {
  status: string;
  // TODO
}

interface RoomDetail {
  room_name: string;
  name: string;
  address: string;
}

interface CancelledBook {
  cancel_reason: string;
  other_reason: string;
}
interface DataDetailSessionResp {
  reservation_code: string;
  rates: Array<SpecialistRate>;
  status: string;
  format_call: string;
  room_link: string;
  event_id: string;
  date: string;
  time_from: string;
  time_to: string;
  member_timezone: MemberTimeZone;
  member_email: string;
  member_username: string;
  psycholog_code: string;
  psycholog_email: string;
  psycholog_username: string;
  psycholog_profile_url: string;
  psycholog_membership: string;
  overtime: PsychologOvertime;
  room_detail: RoomDetail;
  amount: number;
  promo_used: number;
  point_used: number;
  cancelled_book: CancelledBook;
  reschedule_reason: string;
  created_date: string;
}

export default function DetailSession(props: { code; onChange: () => void }) {
  const [dataDetailSession, setDataDetailSession] = useState<any>();
  const [dataNewSchedule, setDataNewSchedule] = useState<any>();

  const [hourReschedule, setHourReschedule] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [days, setDays] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const IN_PERSON_CHILD = "in_person_child";
  const IN_PERSON_COUPLE = "in_person_couple";
  const IN_PERSON_FAMILY = "in_person_family";
  const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
  const IN_PERSON_INDIVIDUAL = "in_person_individual";
  const ONLINE_CALL_INDIVIDUAL = "online_call_individual";

  const fetchDetailSession = async (code) => {
    const res = await getSessionDetail({
      code: code,
      lang: "id",
    });
    setDataDetailSession(res.data);
  };

  const postUpdateStatus = async () => {
    updateSessionStatus({
      code: props.code,
      status: dataDetailSession.status === "done" ? "confirmed" : "done",
      roomLink: dataDetailSession.room_link,
      eventId: dataDetailSession.event_id,
    }).then((res) => {
      window.location.reload();
    });
  };

  useEffect(() => {
    if (dataDetailSession !== undefined) {
      setHourReschedule(
        checkDisabledReschedule({
          date: dataDetailSession.date + " " + dataDetailSession.time_from,
        }).hours()
      );

      const interval = setInterval(() => {
        setDays(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_to,
          }).days()
        );
        setHours(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_to,
          }).hours()
        );
        setMinutes(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_to,
          }).minutes()
        );
        setSeconds(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_to,
          }).seconds()
        );
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [dataDetailSession]);

  const [dataOvertime, setDataOvertime] = useState<any>();
  const checkDataOvertime = async (code) => {
    checkOvertime({
      code: code,
    }).then((res) => {
      setDataOvertime(res.data);
    });
  };

  const [dataReview, setReview] = useState<any>();
  const checkReviewData = async (code) => {
    checkReview({
      code: code,
      lang: "id",
    }).then((res) => {
      setReview(res.data);
    });
  };

  useEffect(() => {
    fetchDetailSession(props.code);
    checkReviewData(props.code);
    checkDataOvertime(props.code);
    fetchCancelReason();
    getNotesFile(props.code);
  }, [props.code]);

  const [showDetailPayment, setShowDetailPayment] = useState(false);

  function getTotalPayment(amount, point, promo) {
    return amount - point - promo;
  }

  const [showCancelRequest, setShowCancelRequest] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [dataCancelReasonChoosed, setDataCancelReasonChoosed] = useState<any>();
  const [dataCancelReason, setDataCancelReason] = useState<any>();
  const [otherReason, setOtherReason] = useState<any>();
  const [rescheduleReason, setRescheduleReason] = useState("");
  const fetchCancelReason = async () => {
    const res = await getCancelReason({
      lang: "id",
    });
    setDataCancelReason(res.data);
    setDataCancelReasonChoosed(res.data[0]);
  };

  const closeModalCancelRequest = async () => {
    if (!isLoadingCancel) {
      setShowCancelRequest(false);
    }
  };

  const onSubmitCancel = async () => {
    setIsLoadingCancel(true);
    postCancel({
      code: props.code,
      email: email,
      phone: phone,
      cancel_code: dataCancelReasonChoosed.cancel_reason_code,
      other_reason:
        dataCancelReasonChoosed.cancel_reason_code === "CRC-006"
          ? otherReason
          : "",
    })
      .then((res) => {
        setIsLoadingCancel(false);
        setShowCancelRequest(false);
        fetchDetailSession(props.code);
      })
      .catch((err) => {
        setIsLoadingCancel(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [reviewSession, setReviewSession] = useState(0);
  const [reviewTextSession, setReviewTextSession] = useState("");
  const [isBtnReviewDisabled, setBtnReviewDisabled] = useState(true);
  const [isLoadingReview, setIsLoadingReview] = useState(false);
  const onSubmitReview = async () => {
    setIsLoadingReview(true);
    addReview({
      code: props.code,
      review: reviewTextSession,
      rating: reviewSession === 0 ? false : true,
      reviewRating: reviewSession,
    })
      .then((res) => {
        setIsLoadingReview(false);
        toast.success("Berhasil memberikan feedback");
        checkReviewData(props.code);
        setReviewSession(0);
        setReviewTextSession("");
      })
      .catch((err) => {
        setIsLoadingReview(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [showOvertimeModal, setShowOvertimeModal] = useState(false);

  const closeModalOvertime = async () => {
    if (!isLoadingCancel) {
      setShowOvertimeModal(false);
    }
  };

  const [showSuccessReschedule, setShowSuccessReschedule] = useState(false);

  const closeSuccessReschedule = async () => {
    if (!isLoadingCancel) {
      setShowSuccessReschedule(false);
    }
  };

  const [showRescheduleModal, setShowRescheduleModal] = useState(false);

  const closeModalReschedule = async () => {
    if (!isLoadingCancel) {
      setShowRescheduleModal(false);
    }
  };

  const reschedule = async (schedule: any, event: any) => {
    putReschedule({
      lang: "id",
      reservationCode: dataDetailSession.reservation_code,
      scheduleCode: schedule.schedule_detail_code,
    }).then((val) => {
      updateDataReservation(event);
    });
  };

  const updateDataReservation = async (event: any) => {
    updateReservation({
      reservationCode: dataDetailSession.reservation_code,
      roomLink: event.conferenceData.entryPoints[0].uri,
      eventId: event.id,
    }).then((val) => {
      window.location.reload();
    });
  };

  const submitRequestReschedule = async () => {
    putRequestReschedule({
      lang: "id",
      reservationCode: dataDetailSession.reservation_code,
      reason: rescheduleReason,
    }).then((val) => {
      window.location.reload();
    });
  };

  const [loadingAddCalendar, setLoadingAddCalendar] = useState(false);
  const addToCalendar = () => {
    setLoadingAddCalendar(true);

    apiCalendar
      .handleAuthClick()
      .then((result: any) => {
        setLoadingAddCalendar(false);
        createEvent();
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
      });
  };

  const rescheduleCalendar = (schedule: any) => {
    setDataNewSchedule(schedule);
    closeModalReschedule();
    setLoadingAddCalendar(true);

    apiCalendar
      .handleAuthClick()
      .then((result: any) => {
        setLoadingAddCalendar(false);
        if (dataDetailSession.room_link === "") {
          createRescheduleEvent(schedule);
        } else {
          updateRescheduleEvent(schedule);
        }
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
      });
  };

  const createEvent = () => {
    console.log("CREATE");
    var _start = moment(
      dataDetailSession.date + " " + dataDetailSession.time_from
    ).format("yyyy-MM-DD HH:mm");
    var _end = moment(
      dataDetailSession.date + " " + dataDetailSession.time_to
    ).format("yyyy-MM-DD HH:mm");
    let events = {
      summary: "Sesi konsultasi 60 menit",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      attendees: [
        {
          email: dataDetailSession.member_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.member_username,
        },
        {
          email: dataDetailSession.psycholog_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: dataDetailSession.reservation_code,
        },
      },
      visibility: "private",
    };

    apiCalendar
      .createEventWithVideoConference(events, "primary", "all")
      .then((data: any) => {
        setLoadingAddCalendar(false);
        toast.success("Sukses menambahkan ke Kalender Google");
        console.log(data.result);
        updateDataReservation(data.result);
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
        toast.success("Gagal menambahkan ke Kalender Google");
        console.log(error);
      });
  };

  const createRescheduleEvent = (schedule: any) => {
    console.log("CREATE");
    var _start = moment(schedule.date + " " + schedule.time_from).format(
      "yyyy-MM-DD HH:mm"
    );
    var _end = moment(schedule.date + " " + schedule.time_to).format(
      "yyyy-MM-DD HH:mm"
    );
    let events = {
      summary: "Sesi konsultasi 60 menit",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      attendees: [
        {
          email: dataDetailSession.member_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.member_username,
        },
        {
          email: dataDetailSession.psycholog_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: dataDetailSession.reservation_code,
        },
      },
      visibility: "private",
    };

    apiCalendar
      .createEventWithVideoConference(events, "primary", "all")
      .then((data: any) => {
        setLoadingAddCalendar(false);
        toast.success("Sukses menambahkan ke Kalender Google");
        console.log(data.result);
        reschedule(schedule, data.result);
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
        toast.success("Gagal menambahkan ke Kalender Google");
        console.log(error);
      });
  };

  const updateRescheduleEvent = (schedule: any) => {
    console.log("UPDATE");
    var _start = moment(schedule.date + " " + schedule.time_from).format(
      "yyyy-MM-DD HH:mm"
    );
    var _end = moment(schedule.date + " " + schedule.time_to).format(
      "yyyy-MM-DD HH:mm"
    );
    let events = {
      summary: "Sesi konsultasi 60 menit",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      attendees: [
        {
          email: dataDetailSession.member_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.member_username,
        },
        {
          email: dataDetailSession.psycholog_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: dataDetailSession.reservation_code,
        },
      },
      visibility: "private",
    };

    apiCalendar
      .updateEvent(events, dataDetailSession.event_id, "primary", "all")
      .then((data: any) => {
        setLoadingAddCalendar(false);
        toast.success("Sukses menambahkan ke Kalender Google");
        console.log(data);
        reschedule(schedule, data.result);
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
        toast.success("Gagal menambahkan ke Kalender Google");
        console.log(error);
      });
  };

  const [isShowModalOvertime, setShowModalOvertime] = useState(false);
  const [isShowModalOvertimeLink, setShowModalOvertimeLink] = useState(false);
  const [isShowModalDeleteOvertime, setShowModalDeleteOvertime] =
    useState(false);

  const onSubmitOvertime = async (val: any) => {
    if (dataOvertime) {
      await updateOvertime({
        reservationCode: dataDetailSession.reservation_code,
        ...val,
      });

      setShowModalOvertime(false);
    } else {
      await addOvertime({
        reservationCode: dataDetailSession.reservation_code,
        ...val,
      });

      setShowModalOvertime(false);
    }
    toast.success("data already updated");
    await checkDataOvertime(props.code);
    setShowModalOvertimeLink(true);
  };

  const onDeleteOvertime = () => {
    if (dataDetailSession.overtime.status !== "created") {
      return toast.warn("cannot delete overtime");
    }

    setShowModalOvertime(false);
    setShowModalDeleteOvertime(true);
  };

  const onEditOvertime = () => {
    setShowModalOvertime(true);
    setShowModalOvertimeLink(false);
  };

  const onDeleteOvertimeAction = async () => {
    await deleteOvertime({
      reservationCode: dataDetailSession.reservation_code,
    });

    setShowModalDeleteOvertime(false);
    toast.success("data already updated");
  };

  const [file, setFile] = useState<File>();

  const [clientNotesFile, setClientNotesFile] = useState<any>();
  const getNotesFile = async (code) => {
    fetchNoteFile({ code: code }).then((res) => {
      setClientNotesFile(res.data);
    });
  };

  const onChangedFile = (e) => {
    if (e.target.files) {
      const maxAllowedSize = 1 * 1024 * 1024;
      if (e.target.files[0].size > maxAllowedSize) {
        toast.error("File terlalu besar");
        return;
      } else {
        setIsLoadingUpload(true);
        setFile(e.target.files[0]);
        submitUploadFile(e.target.files[0]);
      }
    }
  };

  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const submitUploadFile = async (_file) => {
    uploadFile(_file)
      .then((res) => {
        addNoteFile({
          code: dataDetailSession.reservation_code,
          file_url: res.data.file_path,
        })
          .then((res) => {
            setIsLoadingUpload(false);
            getNotesFile(dataDetailSession.reservation_code);
          })
          .catch((err) => {
            setIsLoadingUpload(false);
            toast.warn(CapitalizeFirstLetter(err));
          });
      })
      .catch((err) => {
        setIsLoadingUpload(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  function downloadFile(_url) {
    const tries = axios.create({
      responseType: "arraybuffer",
      responseEncoding: "binary",
      headers: {
        "Content-Type": "application/pdf",
      },
    });
    tries.get(config.AWS_URL_IMAGES + _url).then((res) => {
      const url = URL.createObjectURL(
        new Blob([res.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `client_notes_${Date.now()}.${GetExtension(_url)}`
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  return (
    <div className="p-6">
      <div className="w-full">
        <h1 className="text-xl font-semibold">Info Sesi</h1>
      </div>
      <div>
        {dataDetailSession !== undefined ? (
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
            {getRoleCookies() === "member" ? bodyMember() : bodySpecialist()}
          </div>
        ) : null}
      </div>

      <ModalOvertime
        hide={() => setShowModalOvertime(false)}
        isShow={isShowModalOvertime}
        onSubmit={onSubmitOvertime}
        onDelete={onDeleteOvertime}
        value={dataDetailSession ?? {}}
        overtime={dataOvertime ?? {}}
      ></ModalOvertime>

      <ModalOvertimeLink
        session={dataDetailSession}
        overtime={dataOvertime}
        isShow={isShowModalOvertimeLink}
        onHide={() => setShowModalOvertimeLink(false)}
        onSubmit={onEditOvertime}
      ></ModalOvertimeLink>

      <ModalDelete
        isOpen={isShowModalDeleteOvertime}
        onClose={() => setShowModalDeleteOvertime(false)}
        onDelete={onDeleteOvertimeAction}
        title="Apakah Anda yakin ingin menghapus overtime ini?"
      ></ModalDelete>

      {showCancelRequest ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalCancelRequest();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[820px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">Batalkan Sesi</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => closeModalCancelRequest()}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col items-center justify-center">
                      <ImgRefundRequest className="my-6" />
                      <p className="font-semibold text-lg">
                        Pengembalian Dana Pembatalan Sesi
                      </p>
                      <p className="text-sm text-text-grey mt-2 mb-2">
                        Layanan pelanggan kami akan menghubungi Anda tentang
                        pengembalian dana pembatalan
                        <br />
                        Anda melalui alamat email yang Anda berikan.
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-start">
                        Pilih Alasan Pembatalan
                      </p>
                      {dataCancelReason !== undefined
                        ? dataCancelReason.map((val, i) => {
                            return (
                              <div
                                key={i}
                                className={
                                  "border border-background-neutral px-4 py-2 rounded-full mt-2 " +
                                  (val.cancel_reason_code ===
                                  dataCancelReasonChoosed.cancel_reason_code
                                    ? "bg-blue-background"
                                    : "bg-white")
                                }
                                onClick={() => setDataCancelReasonChoosed(val)}
                              >
                                <p className="text-sm">{val.name}</p>
                              </div>
                            );
                          })
                        : null}
                      {dataCancelReasonChoosed.cancel_reason_code ===
                      "CRC-006" ? (
                        <textarea
                          id="textarea"
                          rows={3}
                          value={otherReason}
                          onChange={(val) => setOtherReason(val.target.value)}
                          className="block p-2.5 mt-3 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
                          placeholder={"Other Reason"}
                        ></textarea>
                      ) : null}
                    </div>
                  </div>

                  <p className="text-sm text-text-black font-medium mt-6">
                    Bagaimana kami dapat menghubungi Anda?
                  </p>
                  <input
                    id="email"
                    defaultValue={email}
                    onChange={(val) => setEmail(val.target.value)}
                    type="email"
                    className=" w-80 mt-2 p-4 h-12 text-gray-900 rounded-full bg-white border border-neutral-300 focus:border-blue-200"
                    placeholder="Alamat email Anda"
                  />
                  <input
                    id="phone"
                    value={phone}
                    onChange={(e) => {
                      const onlyDigits = e.target.value.replace(/\D/g, "");
                      setPhone(onlyDigits);
                    }}
                    type="text"
                    pattern="[0-9]*"
                    className=" w-80 mt-2 p-4 h-12 text-gray-900 rounded-full bg-white border border-neutral-300 focus:border-blue-200"
                    placeholder="Nomor HP Anda"
                  />
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-80 h-12 mt-6 disabled:bg-button-disabled"
                    disabled={
                      dataCancelReasonChoosed === undefined ||
                      email === "" ||
                      phone === ""
                    }
                    onClick={() => onSubmitCancel()}
                  >
                    <strong>{isLoadingCancel ? "Mengirim..." : "Kirim"}</strong>
                    {isLoadingCancel ? (
                      <div
                        className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      ></div>
                    ) : null}
                  </button>
                  <p className="text-sm mt-4 text-text-black">
                    Apakah Anda ingin mengobrol dengan layanan pelanggan kami?
                  </p>
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => onContactUs()}
                  >
                    Hubungi kami di WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showOvertimeModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalOvertime();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[520px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">
                      Harga Kelebihan Waktu
                    </h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => closeModalOvertime()}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>
                  <div className="border border-custom-border p-4 w-full rounded-lg text-start mt-4">
                    <p className="text-sm">Detail Kelebihan Waktu</p>
                    <Divider className="my-3" />
                    <p className="text-xs text-text-grey font-medium">Waktu</p>
                    <p className="text-sm text-text-black mb-3 mt-1">
                      {dataOvertime.duration + " menit"}
                    </p>
                    <p className="text-xs text-text-grey font-medium">Total</p>
                    <p className="text-sm text-text-black mb-3 mt-1">
                      {formatRupiah(dataOvertime.price)}
                    </p>
                    <p className="text-xs text-text-grey font-medium">Status</p>
                    <p className="text-sm text-text-black mt-1">
                      {dataOvertime.status === "confirmed"
                        ? "Dibayar"
                        : "Belum dibayar"}
                    </p>
                  </div>
                  {dataOvertime.status !== "confirmed" ? (
                    <div>
                      <div className="border border-custom-border p-4 w-full rounded-lg text-start mt-4">
                        <p className="text-sm">Tautan Pembayaran</p>
                        <Divider className="my-3" />
                        <p className="text-sm text-text-black font-semibold">
                          Konfirmasi Harga Kelebihan Waktu
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Nama: " + dataDetailSession.psycholog_username}
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Sesi: " +
                            dataOvertime.duration +
                            " menit kelebihan waktu"}
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Tanggal: " +
                            moment(dataOvertime.created_date).format(
                              "DD MMMM YYYY"
                            )}
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Waktu: " +
                            moment(dataDetailSession.time_from, "HH:mm").format(
                              "HH:mm A"
                            )}
                        </p>
                        <p className="text-sm text-text-black mt-4">
                          Gunakan tautan ini untuk menyelesaikan pembayaran Anda
                          melalui situs web:{" "}
                          <Link
                            to={
                              config.LANDING_URL +
                              "/booking/reservation/" +
                              dataDetailSession.reservation_code +
                              "/overtime"
                            }
                            target="blank"
                          >
                            <span className="text-text-dark-blue cursor-pointer">
                              {config.LANDING_URL}
                            </span>
                          </Link>
                        </p>
                      </div>

                      <Link
                        to={
                          config.LANDING_URL +
                          "/booking/reservation/" +
                          dataDetailSession.reservation_code +
                          "/overtime"
                        }
                        target="blank"
                      >
                        <button
                          type="button"
                          className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full px-8 h-12 mt-4 disabled:bg-slate-400 disabled:cursor-not-allowed"
                        >
                          <p className="font-medium">Bayar Sekarang</p>
                        </button>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showRescheduleModal ? (
        <RescheduleSection
          code={dataDetailSession.reservation_code}
          psychologCode={dataDetailSession.psycholog_code}
          formatCall={dataDetailSession.format_call}
          onClose={() => closeModalReschedule()}
          onReschedule={(scheduleCode: any) => rescheduleCalendar(scheduleCode)}
        />
      ) : null}

      {showSuccessReschedule ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalOvertime();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[520px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <h1 className="text-2xl font-bold text-text-dark-blue">
                    Penjadwalan Ulang Sukses
                  </h1>
                  <p>
                    Sesi Anda telah dijadwalkan ulang
                    <br />
                    to{" "}
                    {moment(
                      dataNewSchedule.date + " " + dataNewSchedule.time_from
                    ).format("yyyy-MM-DD HH:mm")}
                  </p>
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full h-12 w-full mt-6"
                    onClick={() => closeSuccessReschedule()}
                  >
                    <p className="font-medium">Lanjutkan</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {loadingAddCalendar ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-white/60">
            <div className="absolute w-full my-6 mx-auto md:max-w-lg">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="px-10 py-6 w-full">
                  <Loading></Loading>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );

  function bodyMember() {
    return (
      <div>
        <div>
          <div className="flex items-end justify-between">
            <p>Status:</p>

            {dataDetailSession.status === "done" ? (
              <div className="px-4 py-2 rounded-lg bg-green-background">
                <p className="text-text-dark-green">Selesai</p>
              </div>
            ) : dataDetailSession.status === "cancelled_book" ? (
              <div className="px-4 py-2 rounded-lg bg-red-background">
                <p className="text-text-dark-red">Dibatalkan</p>
              </div>
            ) : (
              <div className="px-4 py-2 rounded-lg bg-blue-background">
                <p className="text-text-dark-blue">
                  {dataDetailSession.status === "rescheduled"
                    ? "Dijadwalkan Ulang"
                    : dataDetailSession.status === "request_reschedule"
                    ? "Dijadwalkan Ulang"
                    : hours === 0 && minutes < 0 && days === 0
                    ? "Berlangsung"
                    : "Dipesan"}
                </p>
              </div>
            )}
          </div>

          {hours === 0 && minutes < 0 && days === 0 ? (
            <div className="flex flex-col items-center w-fill mt-2">
              <p className="text-sm text-text-grey">Waktu tersisa:</p>
              <div className="bg-blue-background w-full py-4 m-auto text-center rounded-lg mt-2 text-xl text-text-dark-blue font-medium">
                {days +
                  " : " +
                  hours +
                  " : " +
                  minutes.toString().replace("-", "") +
                  " : " +
                  seconds.toString().replace("-", "")}
              </div>
            </div>
          ) : null}
        </div>

        <div className="border border-custom-border rounded-lg p-4 mt-3">
          <div key={dataDetailSession.reservation_code} className="w-full">
            <div className="w-12 float-left">
              <img
                src={
                  config.AWS_URL_IMAGES +
                  dataDetailSession.psycholog_profile_url
                }
                className="w-10 h-10 rounded-full object-cover"
                alt=""
              />
            </div>
            <div className="ml-2">
              <p className="font-medium text-sm">
                {dataDetailSession.psycholog_username}
              </p>
              <p className="font-normal text-text-grey text-xs">
                {CapitalizeFirstLetter(dataDetailSession.psycholog_membership)}
              </p>
            </div>
          </div>
          <Divider className="py-4" />
          <div>
            <p className="text-xs font-medium text-text-grey">Janji Ketemu</p>
            <p className="text-sm pt-1">
              {dataDetailSession.format_call === IN_PERSON_INDIVIDUAL
                ? "Face to face (Individual)"
                : dataDetailSession.format_call === IN_PERSON_COUPLE
                ? "Face to face (Couple)"
                : dataDetailSession.format_call === IN_PERSON_FAMILY
                ? "Face to face (Family)"
                : dataDetailSession.format_call === IN_PERSON_CHILD
                ? "Face to face (Child)"
                : dataDetailSession.format_call === IN_PERSON_HYPNOTHERAPY
                ? "Face to face (Hypnotherapy)"
                : dataDetailSession.format_call === ONLINE_CALL_INDIVIDUAL
                ? "Online Call (Individual)"
                : "Online Call (Couple)"}{" "}
              60 minutes
            </p>
          </div>
          <div className="mt-2">
            <p className="text-xs font-medium text-text-grey">
              Tanggal dan Waktu
            </p>
            <p className="text-sm pt-1">
              {moment(dataDetailSession.created_date).format(
                "MMMM D YYYY, hh:mm A"
              )}
            </p>
          </div>
          {dataDetailSession.format_call?.includes("in_person") && (
            <div className="mt-2">
              <p className="text-xs font-medium text-text-grey">Lokasi</p>
              <p className="text-sm pt-1">
                {dataDetailSession.room_detail.room_name} -{" "}
                {dataDetailSession.room_detail.name}
                <br />
                {dataDetailSession.room_detail.address}
              </p>
            </div>
          )}
          <div className="mt-2">
            <p className="text-xs font-medium text-text-grey">Zona Waktu</p>
            <p className="text-sm pt-1">
              {dataDetailSession.member_timezone.title +
                " (" +
                dataDetailSession.member_timezone.zone +
                getTimezone({
                  offset: dataDetailSession.member_timezone.offset,
                }) +
                ")"}
            </p>
          </div>
          <Divider className="py-4" />
          <div>
            {showDetailPayment ? (
              <div>
                <p className="text-sm font-medium">Detail Pembayaran</p>
                <div className="flex items-center justify-between pt-1.5">
                  <p className="text-sm text-text-grey">Subtotal</p>
                  <p className="text-sm text-text-grey">
                    {formatRupiah(dataDetailSession.amount)}
                  </p>
                </div>
                <div className="flex items-center justify-between pt-1.5">
                  <p className="text-sm text-text-grey">Promo</p>
                  <p className="text-sm text-text-grey">
                    {"-" + formatRupiah(dataDetailSession.promo_used)}
                  </p>
                </div>
                <div className="flex items-center justify-between pt-1.5">
                  <p className="text-base">Total</p>
                  <p className="text-base">
                    {formatRupiah(
                      getTotalPayment(
                        dataDetailSession.amount,
                        dataDetailSession.point_used,
                        dataDetailSession.promo_used
                      )
                    )}
                  </p>
                </div>
                <div className="flex items-center justify-center pt-1.5">
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => setShowDetailPayment(false)}
                  >
                    Sembunyikan Detail
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <p className="text-xs font-medium text-text-grey">
                  Jumlah Pembayaran
                </p>
                <div className="flex items-center justify-between">
                  <p className="text-sm pt-1">
                    {formatRupiah(
                      getTotalPayment(
                        dataDetailSession.amount,
                        dataDetailSession.point_used,
                        dataDetailSession.promo_used
                      )
                    )}
                  </p>
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => setShowDetailPayment(true)}
                  >
                    Lihat Detail
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        {dataDetailSession.status === "done" ? (
          <div>
            {dataOvertime !== undefined ? (
              dataOvertime !== null ? (
                <div
                  className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
                  onClick={() => setShowOvertimeModal(true)}
                >
                  <div>
                    <p className="text-xs text-text-grey">
                      Harga Kelebihan Waktu
                    </p>
                    <p className="text-sm mt-1">
                      {dataOvertime.duration + " menit "}
                      <span className="text-text-dark-blue">
                        {"(" + formatRupiah(dataOvertime.price) + ")"}
                      </span>
                    </p>
                  </div>
                  {dataOvertime.status === "confirmed" ? (
                    <IconCircleFill style={{ fill: "#167CB8" }} />
                  ) : (
                    <ArrowRight2 size="20" color="#292A2E" />
                  )}
                </div>
              ) : null
            ) : null}

            {clientNotesFile ? (
              <div className="border border-custom-border rounded-lg p-4 mt-3">
                <p className="text-sm">Lampiran</p>
                <div className="flex flex-col gap-2 mt-2">
                  {clientNotesFile.map((val, i) => {
                    return (
                      <div className="flex items-center justify-around gap-2">
                        <IconFile width={20} height={20} />
                        <p className="w-full text-sm">
                          {"File " + (i + 1) + "." + GetExtension(val.file_url)}
                        </p>
                        <p
                          onClick={() => downloadFile(val.file_url)}
                          className="text-sm font-medium text-text-dark-blue  cursor-pointer"
                        >
                          Download
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className="border border-custom-border rounded-lg p-4 mt-3">
              <p className="text-sm">Apakah sesi ini membantu?</p>
              {dataReview !== null && dataReview !== undefined ? (
                <div>
                  <Rating
                    name="half-rating"
                    value={dataReview.review_rating}
                    readOnly
                    precision={1}
                    icon={<IconStarFilled />}
                    emptyIcon={<IconStarEmpty />}
                    className="gap-2 mt-2"
                  />
                  <p className="text-text-grey mt-1 text-sm">
                    {dataReview.review}
                  </p>
                </div>
              ) : (
                <div>
                  <div className="flex justify-center items-center mt-4">
                    <Rating
                      name="half-rating"
                      value={reviewSession}
                      onChange={(event, newValue) => {
                        setReviewSession(newValue!);
                      }}
                      precision={1}
                      icon={<IconStarFilled />}
                      emptyIcon={<IconStarEmpty />}
                      className="gap-2"
                    />
                  </div>
                  <textarea
                    defaultValue={reviewTextSession}
                    placeholder="Tulis dibawah feedback Anda"
                    onChange={(val) => {
                      setReviewTextSession(val.target.value);
                      setBtnReviewDisabled(val.target.value === "");
                    }}
                    rows={5}
                    className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-lg mt-4 text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue resize-none"
                  />
                  <div className="w-full flex items-center justify-center">
                    <button
                      type="button"
                      disabled={isBtnReviewDisabled}
                      className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-auto px-16 h-10 mt-4 disabled:bg-slate-400	disabled:cursor-not-allowed"
                      onClick={() => onSubmitReview()}
                    >
                      <strong>
                        {isLoadingReview ? "Mengirim..." : "Kirim"}
                      </strong>
                      {isLoadingReview ? (
                        <div
                          className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          role="status"
                        ></div>
                      ) : null}
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div>
              <p className="text-center	text-xs text-text-grey mt-4 px-4">
                Tidak puas dengan sesi ini?
              </p>
              <p
                className="text-center	text-xs text-text-dark-blue mt-1 px-4 cursor-pointer"
                onClick={onContactUs}
              >
                Hubungi kami di WhatsApp
              </p>
            </div>

            <Link
              to={
                config.LANDING_URL +
                "/booking/profile/" +
                dataDetailSession.psycholog_code
              }
            >
              <div className="bg-button-black h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-5">
                <p className="text-white ml-2">Pesan Lagi</p>
              </div>
            </Link>
          </div>
        ) : (
          <div>
            {dataDetailSession.room_link === "" ? (
              <div
                className="flex items-center justify-between border border-custom-border rounded-full p-4 mt-3 cursor-pointer"
                onClick={() => addToCalendar()}
              >
                <IconCalendarGoogle />
                <p className="text-sm font-medium">Tambah ke Kalender</p>
                <span className="w-[20px]"></span>
              </div>
            ) : (
              <div className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer">
                <div>
                  <p className="text-sm">Tautan Google Meet</p>{" "}
                  <p
                    className="text-sm text-text-dark-blue"
                    onClick={() => {
                      if (dataDetailSession.room_link)
                        onJoinGMeet(dataDetailSession.room_link);
                    }}
                  >
                    {dataDetailSession.room_link.replaceAll("https://", "")}
                  </p>
                </div>
                <IconCopy
                  onClick={() => {
                    navigator.clipboard.writeText(dataDetailSession.room_link);
                    toast.success("Tautan berhasil disalin");
                  }}
                />
              </div>
            )}

            {dataOvertime !== undefined ? (
              dataOvertime !== null ? (
                <div
                  className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
                  onClick={() => setShowOvertimeModal(true)}
                >
                  <div>
                    <p className="text-xs text-text-grey">
                      Harga Kelebihan Waktu
                    </p>
                    <p className="text-sm mt-1">
                      {dataOvertime.duration + " menit "}{" "}
                      <span className="text-text-dark-blue">
                        {"(" + formatRupiah(dataOvertime.price) + ")"}
                      </span>
                    </p>
                  </div>
                  {dataOvertime.status === "confirmed" ? (
                    <IconCircleFill style={{ fill: "#167CB8" }} />
                  ) : (
                    <ArrowRight2 size="20" color="#292A2E" />
                  )}
                </div>
              ) : null
            ) : null}

            {dataDetailSession.status === "cancelled_book" ? (
              <div className="mt-4">
                <p className="text-xs font-medium text-text-grey">
                  Alasan Pembatalan:
                </p>
                <p className="text-sm text-text-black mt-1">
                  {dataDetailSession.cancelled_book.cancel_reason ===
                    "Other reasons" ||
                  dataDetailSession.cancelled_book.cancel_reason ===
                    "Alasan lain"
                    ? dataDetailSession.cancelled_book.cancel_reason +
                      ": " +
                      dataDetailSession.cancelled_book.other_reason
                    : dataDetailSession.cancelled_book.cancel_reason}
                </p>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-2 gap-4">
                  <div
                    className={`flex items-center justify-center border border-custom-border rounded-lg p-4 mt-3 cursor-pointer ${
                      utils.validateRescheduleAndCancel(
                        hourReschedule,
                        days,
                        18
                      )
                        ? ""
                        : "bg-button-disabled"
                    }`}
                    onClick={() => {
                      if (
                        utils.validateRescheduleAndCancel(
                          hourReschedule,
                          days,
                          18
                        )
                      )
                        setShowRescheduleModal(true);
                    }}
                  >
                    <Calendar
                      size="20"
                      color={`${
                        utils.validateRescheduleAndCancel(
                          hourReschedule,
                          days,
                          18
                        )
                          ? "#1F1F21"
                          : "#767E8C"
                      }`}
                      variant="Bold"
                    />
                    <p
                      className={`text-sm ml-2 ${
                        utils.validateRescheduleAndCancel(
                          hourReschedule,
                          days,
                          18
                        )
                          ? ""
                          : "text-text-grey"
                      }`}
                    >
                      Jadwal Ulang
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      if (
                        utils.validateRescheduleAndCancel(
                          hourReschedule,
                          days,
                          18
                        )
                      )
                        setShowCancelRequest(true);
                    }}
                    className={`flex items-center justify-center border border-custom-border rounded-lg p-4 mt-3 cursor-pointer ${
                      utils.validateRescheduleAndCancel(
                        hourReschedule,
                        days,
                        18
                      )
                        ? ""
                        : "bg-button-disabled"
                    }`}
                  >
                    <CloseCircle
                      size="20"
                      color={`${
                        utils.validateRescheduleAndCancel(
                          hourReschedule,
                          days,
                          18
                        )
                          ? "#1F1F21"
                          : "#767E8C"
                      }`}
                      variant="Bold"
                    />
                    <p
                      className={`text-sm ml-2 ${
                        utils.validateRescheduleAndCancel(
                          hourReschedule,
                          days,
                          18
                        )
                          ? ""
                          : "text-text-grey"
                      }`}
                    >
                      Batalkan
                    </p>
                  </div>
                </div>

                <p className="text-center	text-xs text-text-grey mt-4 px-4">
                  Catatan: Sesi hanya bisa dijadwalkan ulang paling lambat 18
                  jam sebelum sesi dimulai.
                </p>
                <div
                  className={`h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4 ${
                    dataDetailSession.room_link
                      ? "bg-button-black"
                      : "bg-gray-200"
                  }`}
                  onClick={() => onJoinGMeet(dataDetailSession.room_link)}
                >
                  <IconPhone style={{ fill: "#fff" }} />
                  <p className="text-white ml-2">Gabung Google Meet</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function todoGetSpecialistRate() {
    const rate_by_format_call = dataDetailSession.rates.find((rate) => {
      return rate.format_call == dataDetailSession.format_call;
    });
    return formatRupiah(rate_by_format_call ? rate_by_format_call.fee : 0);
  }

  function bodySpecialist() {
    return (
      <div>
        <div>
          <div className="flex items-end justify-between">
            <p>Status:</p>

            {dataDetailSession.status === "done" ? (
              <div className="px-4 py-2 rounded-lg bg-green-background">
                <p className="text-text-dark-green">Selesai</p>
              </div>
            ) : dataDetailSession.status === "cancelled_book" ? (
              <div className="px-4 py-2 rounded-lg bg-red-background">
                <p className="text-text-dark-red">Dibatalkan</p>
              </div>
            ) : (
              <div className="px-4 py-2 rounded-lg bg-blue-background">
                <p className="text-text-dark-blue">
                  {dataDetailSession.status === "rescheduled"
                    ? "Dijadwalkan Ulang"
                    : dataDetailSession.status === "request_reschedule"
                    ? "Dijadwalkan Ulang"
                    : hours === 0 && minutes < 0 && days === 0
                    ? "Berlangsung"
                    : "Dipesan"}
                </p>
              </div>
            )}
          </div>

          {hours === 0 && minutes < 0 && days === 0 ? (
            <div className="flex flex-col items-center w-fill mt-2">
              <p className="text-sm text-text-grey">Waktu tersisa:</p>
              <div className="bg-blue-background w-full py-4 m-auto text-center rounded-lg mt-2 text-xl text-text-dark-blue font-medium">
                {days +
                  " : " +
                  hours +
                  " : " +
                  minutes.toString().replace("-", "") +
                  " : " +
                  seconds.toString().replace("-", "")}
              </div>
            </div>
          ) : null}
        </div>

        <div className="border border-custom-border rounded-lg p-4 mt-3">
          <div key={dataDetailSession.reservation_code} className="w-full">
            <div className="flex items-center justify-center w-[40px] h-[40px] bg-custom-white mx-auto rounded-full float-left mr-3">
              <p className="text-16 font-medium">
                {dataDetailSession.member_username
                  ? dataDetailSession.member_username.substring(0, 1)
                  : "?"}
              </p>
            </div>
            <div className="ml-2">
              <p className="font-medium text-sm">
                {dataDetailSession.member_username}
              </p>
              <p className="font-normal text-text-grey text-xs">Member</p>
            </div>
          </div>
          <Divider className="py-4" />
          <div>
            <p className="text-xs font-medium text-text-grey">Janji Ketemu</p>
            <p className="text-sm pt-1">
              {dataDetailSession.format_call === IN_PERSON_INDIVIDUAL
                ? "Face to face (Individual)"
                : dataDetailSession.format_call === IN_PERSON_COUPLE
                ? "Face to face (Couple)"
                : dataDetailSession.format_call === IN_PERSON_FAMILY
                ? "Face to face (Family)"
                : dataDetailSession.format_call === IN_PERSON_CHILD
                ? "Face to face (Child)"
                : dataDetailSession.format_call === IN_PERSON_HYPNOTHERAPY
                ? "Face to face (Hypnotherapy)"
                : dataDetailSession.format_call === ONLINE_CALL_INDIVIDUAL
                ? "Online Call (Individual)"
                : "Online Call (Couple)"}{" "}
              60 minutes
            </p>
          </div>
          <div className="mt-2">
            <p className="text-xs font-medium text-text-grey">
              Tanggal dan Waktu
            </p>
            <p className="text-sm pt-1">
              {moment(dataDetailSession.created_date).format(
                "MMMM D YYYY, hh:mm A"
              )}
            </p>
          </div>
          {dataDetailSession.format_call?.includes("in_person") && (
            <div className="mt-2">
              <p className="text-xs font-medium text-text-grey">Lokasi</p>
              <p className="text-sm pt-1">
                {dataDetailSession.room_detail.room_name} -{" "}
                {dataDetailSession.room_detail.name}
                <br />
                {dataDetailSession.room_detail.address}
              </p>
            </div>
          )}
          <div className="mt-2">
            <p className="text-xs font-medium text-text-grey">Zona Waktu</p>
            <p className="text-sm pt-1">
              {dataDetailSession.member_timezone.title +
                " (" +
                dataDetailSession.member_timezone.zone +
                getTimezone({
                  offset: dataDetailSession.member_timezone.offset,
                }) +
                ")"}
            </p>
          </div>
          <Divider className="py-4" />
          <div>
            <p className="text-xs font-medium text-text-grey">
              Jumlah pembayaran
            </p>
            <div className="flex items-center justify-between">
              <p className="text-sm pt-1">{todoGetSpecialistRate()}</p>
            </div>
          </div>
        </div>

        {dataDetailSession.status === "done" ? (
          <div>
            <div
              className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
              onClick={() => {
                if (dataOvertime) {
                  setShowModalOvertimeLink(true);
                } else {
                  setShowModalOvertime(true);
                }
              }}
            >
              {dataOvertime ? (
                <div>
                  <p className="text-xs text-text-grey">
                    Harga Kelebihan Waktu
                  </p>
                  <p className="text-sm mt-1">
                    {dataOvertime.duration + " menit "}
                    <span className="text-text-dark-blue">
                      {"(" + formatRupiah(dataOvertime.price) + ")"}
                    </span>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-sm">Tambah Harga Kelebihan Waktu</p>
                </div>
              )}
              <ArrowRight2 size="20" color="#292A2E" />
            </div>

            <div className="border border-custom-border rounded-lg p-4 mt-3">
              <p className="text-sm">Lampiran</p>
              {clientNotesFile ? (
                <div className="flex flex-col gap-2 mt-2">
                  {clientNotesFile.map((val, i) => {
                    return (
                      <div className="flex items-center justify-around gap-2">
                        <IconFile width={20} height={20} />
                        <p className="w-full text-sm">
                          {"File " + (i + 1) + "." + GetExtension(val.file_url)}
                        </p>
                        <p
                          onClick={() => downloadFile(val.file_url)}
                          className="text-sm font-medium text-text-dark-blue  cursor-pointer"
                        >
                          Download
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex items-center gap-1 justify-start mt-1">
                  <IconAttachment />
                  <div className="">
                    <label
                      htmlFor="myInput"
                      className={`text-xs cursor-pointer mb-1 ${
                        isLoadingUpload
                          ? "text-neutral-secondary"
                          : file
                          ? "text-text-dark-blue"
                          : "text-neutral-secondary"
                      }`}
                    >
                      {isLoadingUpload
                        ? "Sedang mengunggah"
                        : file
                        ? "File Lampiran"
                        : "Tambah Lampiran"}
                    </label>
                    <input
                      id="myInput"
                      style={{ display: "none" }}
                      type={"file"}
                      accept="application/pdf, image/*"
                      onChange={onChangedFile}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="border border-custom-border rounded-lg p-4 mt-3">
              <p className="text-sm">Review</p>
              {dataReview !== null && dataReview !== undefined ? (
                <div>
                  <Rating
                    name="half-rating"
                    value={dataReview.review_rating}
                    readOnly
                    precision={1}
                    icon={<IconStarFilled />}
                    emptyIcon={<IconStarEmpty />}
                    className="gap-2 mt-2"
                  />
                  <p className="text-text-grey mt-1 text-sm">
                    {dataReview.review}
                  </p>
                </div>
              ) : (
                <p className="text-xs text-text-grey">Belum Ada Review</p>
              )}
            </div>
          </div>
        ) : (
          <div>
            {dataDetailSession.room_link === "" ? (
              <div
                className="flex items-center justify-between border border-custom-border rounded-full p-4 mt-3 cursor-pointer"
                onClick={() => addToCalendar()}
              >
                <IconCalendarGoogle />
                <p className="text-sm font-medium">Tambah ke Kalender</p>
                <span className="w-[20px]"></span>
              </div>
            ) : (
              <div className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer">
                <div>
                  <p className="text-sm">Tautan Google Meet</p>{" "}
                  <p
                    className="text-sm text-text-dark-blue"
                    onClick={() => onJoinGMeet(dataDetailSession.room_link)}
                  >
                    {dataDetailSession.room_link.replaceAll("https://", "")}
                  </p>
                </div>
                <IconCopy
                  onClick={() => {
                    navigator.clipboard.writeText(dataDetailSession.room_link);
                    toast.success("Tautan berhasil disalin");
                  }}
                />
              </div>
            )}

            <div
              className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
              onClick={() => {
                if (dataOvertime) {
                  setShowModalOvertimeLink(true);
                } else {
                  setShowModalOvertime(true);
                }
              }}
            >
              {dataOvertime ? (
                <div>
                  <p className="text-xs text-text-grey">
                    Harga Kelebihan Waktu
                  </p>
                  <p className="text-sm mt-1">
                    {dataOvertime.duration + " menit "}
                    <span className="text-text-dark-blue">
                      {"(" + formatRupiah(dataOvertime.price) + ")"}
                    </span>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-sm">Tambah Harga Kelebihan Waktu</p>
                </div>
              )}
              <ArrowRight2 size="20" color="#292A2E" />
            </div>

            {dataDetailSession.status === "cancelled_book" ? (
              <div className="mt-4">
                <p className="text-xs font-medium text-text-grey">
                  Alasan Pembatalan:
                </p>
                <p className="text-sm text-text-black mt-1">
                  {dataDetailSession.cancelled_book.cancel_reason ===
                    "Other reasons" ||
                  dataDetailSession.cancelled_book.cancel_reason ===
                    "Alasan lain"
                    ? dataDetailSession.cancelled_book.cancel_reason +
                      ": " +
                      dataDetailSession.cancelled_book.other_reason
                    : dataDetailSession.cancelled_book.cancel_reason}
                </p>
              </div>
            ) : (
              <div>
                {dataDetailSession.status === "request_reschedule" ? (
                  <div>
                    <div className="flex flex-col gap-1 mt-4">
                      <p className="text-xs text-neutral-secondary font-medium">
                        Alasan Permintaan Penjadwalan Ulang:
                      </p>
                      <p className="text-sm">
                        {dataDetailSession.reschedule_reason}
                      </p>
                    </div>
                    <div
                      className={`h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4 ${
                        dataDetailSession.room_link
                          ? "bg-button-black"
                          : "bg-gray-200"
                      }`}
                      onClick={() => {
                        if (dataDetailSession.room_link)
                          onJoinGMeet(dataDetailSession.room_link);
                      }}
                    >
                      <IconPhone style={{ fill: "#fff" }} />
                      <p className="text-white ml-2">Gabung Google Meet</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className={`flex flex-col gap-2 items-center border border-custom-border rounded-lg p-4 mt-3 cursor-pointer ${
                        utils.validateRescheduleAndCancel(
                          hourReschedule,
                          days,
                          18
                        )
                          ? ""
                          : "bg-button-disabled"
                      }`}
                    >
                      <div className="flex items-start justify-start w-full">
                        <Calendar
                          size="20"
                          color={`${
                            utils.validateRescheduleAndCancel(
                              hourReschedule,
                              days,
                              18
                            )
                              ? "#1F1F21"
                              : "#767E8C"
                          }`}
                          variant="Bold"
                        />
                        <p
                          className={`text-sm ml-2 ${
                            utils.validateRescheduleAndCancel(
                              hourReschedule,
                              days,
                              18
                            )
                              ? ""
                              : "text-text-grey"
                          }`}
                        >
                          Minta Penjadwalan Ulang
                        </p>
                      </div>
                      <p className="text-xs text-neutral-secondary">
                        Kami akan memberi tahu klien Anda tentang alasan Anda
                        menjadwal ulang.
                      </p>
                      <textarea
                        id="textarea"
                        rows={3}
                        value={rescheduleReason}
                        onChange={(val) =>
                          setRescheduleReason(val.target.value)
                        }
                        className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
                        placeholder={"Silakan jelaskan alasan Anda di sini."}
                      ></textarea>

                      <button
                        className={`bg-button-black rounded-full text-white px-20 h-10 text-sm disabled:bg-slate-300`}
                        disabled={
                          rescheduleReason === "" ||
                          !utils.validateRescheduleAndCancel(
                            hourReschedule,
                            days,
                            18
                          )
                        }
                        onClick={() => {
                          if (
                            utils.validateRescheduleAndCancel(
                              hourReschedule,
                              days,
                              18
                            )
                          )
                            submitRequestReschedule();
                        }}
                      >
                        <p>Kirim Permintaan</p>
                      </button>
                    </div>

                    <p className="text-center	text-xs text-text-grey mt-4 px-4">
                      Catatan: Sesi hanya bisa dijadwalkan ulang paling lambat
                      18 jam sebelum sesi dimulai.
                    </p>
                    <div
                      className={`h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4 ${
                        dataDetailSession.room_link
                          ? "bg-button-black"
                          : "bg-gray-200"
                      }`}
                      onClick={() => {
                        if (dataDetailSession.room_link)
                          onJoinGMeet(dataDetailSession.room_link);
                      }}
                    >
                      <IconPhone style={{ fill: "#fff" }} />
                      <p className="text-white ml-2">Gabung Google Meet</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
