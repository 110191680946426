import config from "helpers/config";
import {
  clearTokenAuth,
  getRoleCookies,
  getTokenAuth,
  getUserdata,
} from "helpers/localStorage";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getProfile } from "services/profile";

//IconSideBar
import { ReactComponent as NavExpandClose } from "assets/icons/nav_expand_close.svg";
import { ReactComponent as NavExpandOpen } from "assets/icons/nav_expand_open.svg";
import { ReactComponent as IconHome } from "assets/icons/ic_sidebar_home.svg";
import { ReactComponent as IconSession } from "assets/icons/ic_sidebar_session.svg";
import { ReactComponent as IconTransaction } from "assets/icons/ic_sidebar_transaction.svg";
import { ReactComponent as IconProfile } from "assets/icons/ic_sidebar_profile.svg";
import { ReactComponent as IconAvailability } from "assets/icons/ic_sidebar_availability.svg";
import { ReactComponent as IconEarning } from "assets/icons/ic_sidebar_earning.svg";
import { Dropdown } from "flowbite-react";
import { LocalStorage } from "helpers";
import { useState } from "react";

export default function Dashboard(props: { children: JSX.Element }) {
  const navigate = useNavigate();

  const onLogout = () => {
    LocalStorage.clearTokenAuth();
    LocalStorage.clearNameCookies();
    LocalStorage.setLastRefreshDate("");
    navigate(`/`);
    window.location.reload();
  };

  const onGetProfile = () => {
    if (getTokenAuth() === null) {
      return () => null;
    }

    // alert(getUserdata().role_name);

    return () => {
      try {
        return getProfile().then((res) => res.data);
      } catch (error) {
        navigate(`/login`);
        return clearTokenAuth();
      }
    };
  };

  const queryProfile = useQuery(
    "getProfile",
    onGetProfile(),
    config.useQuery.fetchOnce as any
  );

  const profile = queryProfile.data ?? {};

  const [navOpen, setNavOpen] = useState(true);

  return (
    <div
      className={`grid w-full ${
        navOpen ? " grid-cols-1 md:grid-cols-5" : "grid-cols-1 md:grid-cols-12"
      }`}
    >
      <div className="hidden md:flex col-span-1">
        <div className={`fixed h-full ${navOpen ? "w-1/5" : "w-1/12"}`}>
          <div className="flex flex-col justify-between w-full h-full py-4 overflow-y-auto bg-white border border-r-grey-500 dark:bg-gray-800">
            <div>
              <div className="flex items-center pl-2.5 relative justify-center mb-5">
                <img
                  src="/images/logo_circle.png"
                  className="w-14 h-14"
                  alt="Ohana Logo"
                />
              </div>
              {getRoleCookies() === "member" ? (
                <ul className="space-y-4 font-medium">
                  <li>
                    <NavLink to="/home" end>
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconHome
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Beranda</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/session">
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconSession
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Sesi</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/transaction">
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconTransaction
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Transaksi</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile" end>
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconProfile
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Profil</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul className="space-y-4 font-medium">
                  <li>
                    <NavLink to="/home" end>
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconHome
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Beranda</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/session">
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconSession
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Sesi</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/availability">
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconAvailability
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Ketersediaan</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/earning" end>
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconEarning
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Pendapatan</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile" end>
                      {({ isActive, isPending }) => (
                        <div
                          className={`flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                            navOpen ? "" : "justify-between"
                          }`}
                        >
                          <div
                            className={
                              isActive
                                ? "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-custom-dark-blue"
                                : "inline-block h-10 w-1 mr-3 rounded-r-full self-stretch bg-transparent"
                            }
                          ></div>
                          <IconProfile
                            style={{ fill: isActive ? "#75C1D5" : "#A0AAB8" }}
                            width={24}
                            height={24}
                          />
                          {navOpen ? (
                            <span className="ml-3 text-4">Profil</span>
                          ) : (
                            <div className="w-4"></div>
                          )}
                        </div>
                      )}
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
            <div className="flex items-center justify-center ">
              {navOpen ? (
                <NavExpandClose
                  onClick={() => setNavOpen(false)}
                  className="cursor-pointer "
                />
              ) : (
                <NavExpandOpen
                  onClick={() => setNavOpen(true)}
                  className="cursor-pointer "
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden justify-between border-b border-grey-500 p-4">
        <img
          src="/images/logo_circle.png"
          className="w-10 h-10"
          alt="Ohana Logo"
        />
      </div>

      <div className={navOpen ? "col-span-4" : "col-span-11"}>
        <div className="p-4 flex items-center justify-between border-b border-grey-500">
          {/* <Search width={"w-80"} placeHolder={"Search"} /> */}
          <div></div>
          <Dropdown
            inline
            label={
              profile.username === undefined ? "" : "Hai, " + profile.username
            }
            size="sm"
          >
            <Dropdown.Header>
              <span className="block text-sm">{profile.username}</span>
              <span className="block truncate text-sm font-medium">
                {profile.email}
              </span>
            </Dropdown.Header>
            <Dropdown.Item onClick={onLogout}>Keluar</Dropdown.Item>
          </Dropdown>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  );
}
