import { ArrowRight2 } from "iconsax-react";
import AboutMe from "./content/about";
import Country from "./content/country";
import { useQuery } from "react-query";
import {
  editProfile,
  editProfileSpecialist,
  getProfile,
} from "services/profile";
import { CapitalizeFirstLetter } from "utils";
import {
  getApproaches,
  getCountry,
  getEducationLevel,
  getExpertise,
  getJob,
  getLanguages,
  getReligion,
  getTarget,
  getYOE,
} from "services/global";
import { getBirthyear } from "services/global";
import { getGender } from "services/global";
import { getMarital } from "services/global";
import Job from "./content/job";
import NoContent from "screens/global/noContent";
import Birthyear from "./content/birthyear";
import { useEffect, useState } from "react";
import { getRoleCookies, getTokenAuth } from "helpers/localStorage";
import Gender from "./content/gender";
import Marital from "./content/marital";
import ChangeEmail from "./content/change_email";
import { Divider } from "components/divider";
import ChangePassword from "./content/change_password";
import ForgotSection from "./content/forgot_section";
import Religion from "./content/religion";
import EducationLevel from "./content/education_level";
import { toast } from "react-toastify";
import Target from "./content/target";
import Expertise from "./content/expertise";
import Approach from "./content/approach";
import Language from "./content/language";
import YOE from "./content/yoe";
import config from "helpers/config";

export default function Profile() {
  const [formChoosed, setFormChoosed] = useState("");

  const [profile, setProfile] = useState<any>();

  const [img, setImg] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [jobCode, setJobCode] = useState("");
  const [country, setCountry] = useState("");
  const [birthyear, setBirthyear] = useState("");
  const [gender, setGender] = useState("");
  const [genderName, setGenderName] = useState("");
  const [religion, setReligion] = useState("");
  const [religionName, setReligionName] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [educationLevelName, setEducationLevelName] = useState("");
  const [marital, setMarital] = useState("");
  const [maritalName, setMaritalName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const onSave = () => {
    setIsLoading(true);
    editProfile({
      username: username,
      phone: phone,
      email: email,
      gender: gender,
      birthyear: birthyear,
      profileUrl: img,
      job: jobCode,
      marital: marital,
      country: country,
      religion: religion,
      educationLevel: educationLevel,
    })
      .then((val) => {
        onGetProfile();
        setIsLoading(false);
        toast.success("Berhasil mengubah data");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onSaveSpecialist = () => {
    setIsLoading(true);

    const _newExpertise: string[] = [];
    for (const _data of profile.expertises) {
      _newExpertise.push(_data.expertise_code);
    }

    const _newTargets: string[] = [];
    for (const _data of profile.target_users) {
      _newTargets.push(_data.target_code);
    }

    const _newApproach: string[] = [];
    for (const _data of profile.approaches) {
      _newApproach.push(_data.approach_code);
    }

    // alert(_newExpertise);

    const body = {
      username: profile.username,
      phone: profile.phone,
      email: profile.email,
      profile_url: profile.profile_url,
      country: profile.country,
      specialist_title_code: profile.specialist_title_code,
      bio: profile.bio,
      gender_key: profile.gender,
      language: profile.language,
      expertises: _newExpertise,
      approaches: _newApproach,
      target_users: _newTargets,
      year_of_experience: profile.year_of_experience,
      bank_name: profile.bank_name,
      bank_account_code: profile.bank_account_code,
      account_holder: profile.account_holder,
      account_number: profile.account_number,
      introduction_video_url: profile.introduction_video_url,
    };

    editProfileSpecialist(body)
      .then((val) => {
        queryProfile.refetch();
        setIsLoading(false);
        toast.success("Berhasil mengubah data");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onGetProfile = () => {
    if (getTokenAuth() === null) {
      return () => null;
    }

    return () =>
      getProfile().then((val) => {
        var _profile = val.data;
        setProfile(_profile);
        setImg(_profile.profile_url);
        setEmail(_profile.email);
        setPhone(_profile.phone);
        setUsername(_profile.username);
        setJobCode(_profile.job_code);
        setCountry(_profile.country);
        setBirthyear(_profile.birthyear);
        setGender(_profile.gender);
        setGenderName(
          _profile.gender === "prefer_not_to_say"
            ? "Lainnya"
            : _profile.gender === "male"
            ? "Laki laki"
            : "Perempuan"
        );
        setReligion(_profile.Religion.id);
        setReligionName(_profile.Religion.name);
        setEducationLevel(_profile.EducationLevel.id);
        setEducationLevelName(_profile.EducationLevel.name);
        setMarital(_profile.marital);
        setMaritalName(
          _profile.marital === "prefer_not_to_say"
            ? "Lainnya"
            : _profile.marital
        );
        return _profile;
      });
  };

  const queryProfile = useQuery("getProfile", onGetProfile());
  const dataProfile = queryProfile.data;

  const onGetCountry = () => {
    return () =>
      getCountry().then((val) => {
        return val.data;
      });
  };

  const queryCountry = useQuery("getCountry", onGetCountry());
  const dataCountry = queryCountry.data;

  const onGetTitle = () => {
    return () =>
      getJob({ lang: "id" }).then((val) => {
        return val.data;
      });
  };

  const queryTitle = useQuery("getTitle", onGetTitle());
  const dataTitle = queryTitle.data;

  const onGetBirthyear = () => {
    return () =>
      getBirthyear().then((val) => {
        return val;
      });
  };

  const queryBirthyear = useQuery("getBirthyear", onGetBirthyear());
  const dataBirthyear = queryBirthyear.data;

  const onGetGender = () => {
    return () =>
      getGender({ lang: "id" }).then((val) => {
        return val;
      });
  };

  const queryGender = useQuery("getGender", onGetGender());
  const dataGender = queryGender.data;

  const onGetReligion = () => {
    return () =>
      getReligion({ lang: "id" }).then((val) => {
        return val.data;
      });
  };

  const queryReligion = useQuery("getReligion", onGetReligion());
  const dataReligion = queryReligion.data;

  const onGetEducationLevel = () => {
    return () =>
      getEducationLevel({ lang: "id" }).then((val) => {
        return val.data;
      });
  };

  const queryEducationLevel = useQuery(
    "getEducationLevel",
    onGetEducationLevel()
  );
  const dataEducationLevel = queryEducationLevel.data;

  const onGetMarital = () => {
    return () =>
      getMarital({ lang: "id" }).then((val) => {
        return val;
      });
  };

  const queryMarital = useQuery("getMarital", onGetMarital());
  const dataMarital = queryMarital.data;

  const onGetLanguage = () => {
    return () =>
      getLanguages({ lang: "id" }).then((val) => {
        return val;
      });
  };

  const queryLanguage = useQuery("getLanguage", onGetLanguage());
  const dataLanguage = queryLanguage.data;

  const onGetYOE = () => {
    return () =>
      getYOE({ lang: "id" }).then((val) => {
        return val;
      });
  };

  const queryYOE = useQuery("getYOE", onGetYOE());
  const dataYOE = queryYOE.data;

  const [dataExpertise, seDataExpertise] = useState<any[]>([]);
  const [dataApproach, setDataApproach] = useState<any[]>([]);
  const [dataTarget, setDataTarget] = useState<any[]>([]);
  useEffect(() => {
    getExpertise({ lang: "id" }).then((val) => {
      const items: any[] = [];
      for (const category of val.data) {
        for (const item of category.expertises) {
          items.push({
            expertise_code: item.expertise_code,
            name: item.name,
            isHeader: false,
          });
        }
      }

      seDataExpertise(items);
    });

    getApproaches({ lang: "id" }).then((val) => {
      const items: any[] = [];
      for (const data of val.data) {
        items.push({
          approach_code: data.approach_code,
          name: data.name,
        });
      }

      setDataApproach(items);
    });

    getTarget({ lang: "id" }).then((val) => {
      setDataTarget(val.data);
    });
  }, []);

  const getDataClients = () => {
    if (profile) {
      if (profile.target_users) {
        const vals = profile.target_users.map((item) => {
          return item.name;
        });
        return vals.join(", ");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getDataExpertise = () => {
    if (profile) {
      if (profile.expertises) {
        const vals = profile.expertises.map((item) => {
          return item.name;
        });
        return vals.join(", ");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getDataApproach = () => {
    if (profile) {
      if (profile.approaches) {
        const vals = profile.approaches.map((item) => {
          return item.name;
        });
        return vals.join(", ");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getDataLanguages = () => {
    if (profile) {
      if (profile.language) {
        const vals = profile.language.map((item) => {
          if (item.toString().toLowerCase() === "english") {
            return "Inggris";
          } else if (item.toString().toLowerCase() === "mandarin") {
            return "Mandarin";
          } else {
            return "Indonesia";
          }
        });
        return vals.join(", ");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getDataYOE = () => {
    if (profile) {
      return profile.year_of_experience
        .toString()
        .toLowerCase()
        .replaceAll("years", "Tahun")
        .replaceAll("year", "Tahun");
    } else {
      return "";
    }
  };

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-6 h-auto min-h-screen">
      <div className="col-span-2 p-4">
        {getRoleCookies() === "member" ? bodyMember() : bodySpecialist()}
      </div>
      <div className="col-span-4 flex flex-row flex-wrap items-center justify-center h-auto max-h-[200px] min-h-full bg-custom-neutral">
        {formChoosed === "country" ? (
          <Country
            data={dataCountry}
            choosedData={country}
            onSave={(name) => {
              setCountry(name);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "title" ? (
          <Job
            data={dataTitle}
            choosedData={jobCode}
            onSave={(code, name) => {
              setJobCode(code);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "birthyear" ? (
          <Birthyear
            data={dataBirthyear}
            choosedData={birthyear}
            onSave={(code) => {
              setBirthyear(code);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "gender" ? (
          <Gender
            data={dataGender}
            choosedData={gender}
            onSave={(code, name) => {
              setGender(code);
              setGenderName(name);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "religion" ? (
          <Religion
            data={dataReligion}
            choosedData={religion}
            onSave={(code, name) => {
              setReligion(code);
              setReligionName(name);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "education-level" ? (
          <EducationLevel
            data={dataEducationLevel}
            choosedData={educationLevel}
            onSave={(code, name) => {
              setEducationLevel(code);
              setEducationLevelName(name);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "marital" ? (
          <Marital
            data={dataMarital}
            choosedData={marital}
            onSave={(code, name) => {
              setMarital(code);
              setMaritalName(name);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "about" ? (
          <AboutMe
            data={profile.bio}
            onSave={(data) => {
              profile.bio = data;
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "change_email" ? (
          <ChangeEmail
            data={dataProfile.email}
            onSave={() => ""}
            onCancel={() => {
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "change_password" ? (
          <ChangePassword
            email={dataProfile.email}
            onSave={() => ""}
            onForgot={() => {
              setFormChoosed("forgot_password");
            }}
            onCancel={() => {
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "forgot_password" ? (
          <ForgotSection
            email={dataProfile.email}
            memberCode={dataProfile.member_code}
          />
        ) : formChoosed === "target" ? (
          <Target
            data={dataTarget}
            choosedData={profile.target_users}
            onSave={(newData) => {
              profile.target_users = newData;
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "expertise" ? (
          <Expertise
            data={dataExpertise}
            choosedData={profile.expertises}
            onSave={(newData) => {
              profile.expertises = newData;
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "approach" ? (
          <Approach
            data={dataApproach}
            choosedData={profile.approaches}
            onSave={(newData) => {
              profile.approaches = newData;
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "language" ? (
          <Language
            data={dataLanguage}
            choosedData={profile.language}
            onSave={(newData) => {
              profile.language = newData;
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "yoe" ? (
          <YOE
            data={dataYOE}
            choosedData={profile.year_of_experience}
            onSave={(newData) => {
              profile.year_of_experience = newData;
              setFormChoosed("none");
            }}
          />
        ) : (
          <NoContent />
        )}
      </div>
    </div>
  );

  function bodyMember() {
    return (
      <form className="mx-auto">
        {dataProfile !== undefined ? (
          <div className="flex items-center justify-center w-[135px] h-[135px] bg-custom-white mx-auto rounded-full">
            <p className="text-[56px] font-medium">
              {username.substring(0, 1)}
            </p>
          </div>
        ) : null}
        <div onClick={() => setFormChoosed("nickname")}>
          <label
            htmlFor="nickname"
            className="text-neutral-secondary font-medium"
          >
            Nama:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              defaultValue={username === undefined ? "" : username}
              placeholder="Masukkan Nama Anda"
              onChange={(val) => setUsername(val.target.value)}
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
            />
          </div>
        </div>

        <div onClick={() => setFormChoosed("country")}>
          <label
            htmlFor="country"
            className="text-neutral-secondary font-medium"
          >
            Negara:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("country")}
              defaultValue={country === undefined ? "" : country}
              placeholder="Pilih Negara Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              onClick={() => setFormChoosed("country")}
            >
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("birthyear")}>
          <label
            htmlFor="birthyear"
            className="text-neutral-secondary font-medium"
          >
            Tahun Lahir:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("birthyear")}
              defaultValue={birthyear === undefined ? "" : birthyear}
              placeholder="Pilih Tahun Lahir Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("gender")}>
          <label
            htmlFor="gender"
            className="text-neutral-secondary font-medium"
          >
            Jenis Kelamin:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("gender")}
              defaultValue={
                gender === undefined ? "" : CapitalizeFirstLetter(genderName)
              }
              placeholder="Pilih Jenis Kelamin"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("education-level")}>
          <label
            htmlFor="marital"
            className="text-neutral-secondary font-medium"
          >
            Tingkat Pendidikan:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("education-level")}
              defaultValue={
                educationLevel === undefined
                  ? ""
                  : CapitalizeFirstLetter(educationLevelName)
              }
              placeholder="Pilih Tingkat Pendidikan Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("religion")}>
          <label
            htmlFor="marital"
            className="text-neutral-secondary font-medium"
          >
            Agama:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              defaultValue={
                religion === undefined
                  ? ""
                  : CapitalizeFirstLetter(religionName)
              }
              onClick={() => setFormChoosed("religion")}
              placeholder="Pilih Agama Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("marital")}>
          <label
            htmlFor="marital"
            className="text-neutral-secondary font-medium"
          >
            Status Pernikahan:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("marital")}
              defaultValue={
                marital === undefined ? "" : CapitalizeFirstLetter(maritalName)
              }
              placeholder="Pilih Status Pernikahan Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <Divider />

        <div className="flex items-center justify-between mt-4">
          <div>
            <p className="text-text-grey">Email</p>
            <p>{email}</p>
          </div>
          <p
            className="text-sm font-semibold text-text-dark-blue cursor-pointer"
            onClick={() => setFormChoosed("change_email")}
          >
            Ubah
          </p>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div>
            <p className="text-text-grey">Password</p>
            <p>*********</p>
          </div>
          <p
            className="text-sm font-semibold text-text-dark-blue cursor-pointer"
            onClick={() => setFormChoosed("change_password")}
          >
            Ubah
          </p>
        </div>

        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full mt-2 w-full h-10"
          onClick={() => onSave()}
        >
          <p className="font-medium">{isLoading ? "Menyimpan..." : "Simpan"}</p>
        </button>
      </form>
    );
  }

  function bodySpecialist() {
    return (
      <form className="mx-auto">
        {profile !== undefined ? (
          <img
            src={config.AWS_URL_IMAGES + profile.profile_url}
            className="w-[135px] h-[135px] rounded-full border mx-auto"
            alt=""
          />
        ) : null}
        <div>
          <label
            htmlFor="nickname"
            className="text-neutral-secondary font-medium"
          >
            Title:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              defaultValue={profile ? profile.specialist_title : ""}
              placeholder="Masukkan Nama Anda"
              readOnly
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer bg-slate-100"
            />
          </div>
        </div>

        <div onClick={() => setFormChoosed("about")}>
          <label htmlFor="about" className="text-neutral-secondary font-medium">
            Tentang Saya:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("bio")}
              defaultValue={profile ? profile.bio : ""}
              placeholder="Masukkan Bio"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              onClick={() => setFormChoosed("bio")}
            >
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("target")}>
          <label
            htmlFor="target"
            className="text-neutral-secondary font-medium"
          >
            Klien Saya:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("target")}
              defaultValue={getDataClients()}
              placeholder="Pilih Klien Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("expertise")}>
          <label
            htmlFor="gender"
            className="text-neutral-secondary font-medium"
          >
            Keahlian Saya:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("expertise")}
              defaultValue={getDataExpertise()}
              placeholder="Pilih Keahlian Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("approach")}>
          <label
            htmlFor="approach"
            className="text-neutral-secondary font-medium"
          >
            Pendekatan Saya:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("approach")}
              defaultValue={getDataApproach()}
              placeholder="Pilih Pendekatan Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("language")}>
          <label
            htmlFor="language"
            className="text-neutral-secondary font-medium"
          >
            Bahasa Saya:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("language")}
              defaultValue={getDataLanguages()}
              placeholder="Pilih Bahasa Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <div onClick={() => setFormChoosed("yoe")}>
          <label htmlFor="yoe" className="text-neutral-secondary font-medium">
            Tahun Pengalaman Saya:
          </label>
          <div className="relative mb-5">
            <input
              type="text"
              onClick={() => setFormChoosed("yoe")}
              defaultValue={getDataYOE()}
              placeholder="Pilih Tahun Pengalaman Anda"
              className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue cursor-pointer"
              readOnly
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ArrowRight2 size="20" className="neutral-black" />
            </div>
          </div>
        </div>

        <Divider />

        <div className="flex items-center justify-between mt-4">
          <div>
            <p className="text-text-grey">Email</p>
            <p>{email}</p>
          </div>
          <p
            className="text-sm font-semibold text-text-dark-blue cursor-pointer"
            onClick={() => setFormChoosed("change_email")}
          >
            Ubah
          </p>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div>
            <p className="text-text-grey">Password</p>
            <p>*********</p>
          </div>
          <p
            className="text-sm font-semibold text-text-dark-blue cursor-pointer"
            onClick={() => setFormChoosed("change_password")}
          >
            Ubah
          </p>
        </div>

        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full mt-2 w-full h-10"
          onClick={() => onSaveSpecialist()}
        >
          <p className="font-medium">{isLoading ? "Menyimpan..." : "Simpan"}</p>
        </button>
      </form>
    );
  }
}
