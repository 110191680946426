import React, { useEffect, useState } from "react";
import NoContent from "screens/global/noContent";
import TimeZone from "./content/timezone";
import { useQuery } from "react-query";
import { getDateRange, getNoticePeriods, getTimeZone } from "services/global";
import { ArrowRight2, Icon } from "iconsax-react";
import DateRange from "./content/daterange";
import {
  fetchSchedule,
  updateSchedule,
  updateSpecialistNoticePeriod,
  updateTimezone,
} from "services/schedule";
import { CapitalizeFirstLetter, getScheduleByDay } from "utils";
import { getProfile } from "services/profile";
import { toast } from "react-toastify";
import NoticePeriod from "./content/noticeperiod";
import CalendarIntegration from "./content/calendarIntegration";
import SettingSchedule from "./content/settingSchedule";

const Availability = () => {
  const [formChoosed, setFormChoosed] = useState("");

  useEffect(() => {
    fetchProfile();
    getSchedule();
  }, []);

  const [dataSchedule, setDataSchedule] = useState<any>();
  const getSchedule = async () => {
    fetchSchedule({ lang: "id" })
      .then((res) => {
        setDataSchedule(res.data);
      })
      .catch((err) => {
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [profile, setProfile] = useState<any>();
  const [cachedTimezone, setCachedTimezone] = useState("");
  const fetchProfile = () => {
    getProfile()
      .then((val) => {
        var _profile = val.data;
        setCachedTimezone(val.data.timezone.title);
        setProfile(_profile);
      })
      .catch((err) => {
        toast.warn(CapitalizeFirstLetter(err));
      });
  };
  const onGetTimeZone = () => {
    return () =>
      getTimeZone().then((val) => {
        return val.data;
      });
  };

  const queryTimeZone = useQuery("getTimeZone", onGetTimeZone());
  const dataTimeZone = queryTimeZone.data;

  const onGetDateRange = () => {
    return () =>
      getDateRange({ lang: "id" }).then((val) => {
        return val;
      });
  };

  const queryDateRange = useQuery("getDateRange", onGetDateRange());
  const dataDateRange = queryDateRange.data;

  const onGetNoticePeriod = () => {
    return () =>
      getNoticePeriods({ lang: "id" }).then((val) => {
        return val.data;
      });
  };

  const queryNoticePeriod = useQuery("getNoticePeriod", onGetNoticePeriod());
  const dataNoticePeriod = queryNoticePeriod.data;

  const onSave = async () => {
    const data = {
      scheduleCode: dataSchedule.schedule_code,
      dateRange: dataSchedule.date_range,
      scheduleDay: dataSchedule.schedule_day,
      timezone: dataSchedule.timezone,
      formatCall: "both_call",
      location: ["Google Meet"],
    };

    await updateSchedule(data);
    await updateSpecialistNoticePeriod({
      noticePeriod: dataSchedule.notice_period,
    });
    toast.success("Jadwal berhasil diubah!");

    getSchedule();
  };

  useEffect(() => {
    if (cachedTimezone !== "" && cachedTimezone !== profile.timezone.title) {
      profile.timezone.title = cachedTimezone;
      updateTimezone({ timezone: cachedTimezone });
      toast.success("Jadwal berhasil diubah!");
    }
  }, [cachedTimezone]);

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-6 h-auto min-h-screen">
      <div className="col-span-2 ">
        <h1 className="text-xl font-semibold p-4">Ketersediaan Saya</h1>
        <div
          className="flex items-center justify-between mt-2 border-b p-4 cursor-pointer hover:bg-blue-background/90"
          onClick={() => setFormChoosed("time_zone")}
        >
          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">Zona Waktu</p>
            <p className="text-sm">
              Pilih zona waktu Anda
              <br />
              Klien melihat waktu lokal mereka
            </p>
          </div>
          <ArrowRight2 size="20" className="neutral-black min-w-[16px]" />
        </div>
        <div
          className="flex items-center justify-between border-b p-4 cursor-pointer hover:bg-blue-background/90"
          onClick={() => setFormChoosed("date_range")}
        >
          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">Rentang Tanggal</p>
            <p className="text-sm">
              Klien dapat memesan ketersediaan Anda hingga 30 atau 60 hari
              kalender bergulir
            </p>
          </div>
          <ArrowRight2 size="20" className="neutral-black min-w-[16px]" />
        </div>

        <div
          className="flex flex-col border-b p-4 cursor-pointer hover:bg-blue-background/90"
          onClick={() => setFormChoosed("schedule")}
        >
          <div
            className="flex items-center justify-between"
            onClick={() => setFormChoosed("schedule")}
          >
            <p className="text-sm font-medium">Jadwal</p>
            <ArrowRight2 size="20" className="neutral-black min-w-[16px]" />
          </div>
          <div className="border rounded-lg mt-4">
            <div className="w-full bg-[#c4e8f7] h-[50px] grid grid-cols-5 items-center px-4 rounded-t-lg">
              <p className="text-base col-span-2">Hari</p>
              <p className="text-base col-span-3">Ketersediaan</p>
            </div>

            <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
              <p className="text-sm col-span-2">Senin</p>
              <p className="text-sm text-neutral-secondary col-span-3">
                {getScheduleByDay("Monday", dataSchedule ?? null)
                  .split("\n")
                  .map(function (item: any, idx: any) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </p>
            </div>

            <div className="w-full bg-[#f5fbfe] py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
              <p className="text-sm col-span-2">Selasa</p>
              <p className="text-sm text-neutral-secondary col-span-3">
                {getScheduleByDay("Tuesday", dataSchedule ?? null)
                  .split("\n")
                  .map(function (item: any, idx: any) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </p>
            </div>

            <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
              <p className="text-sm col-span-2">Rabu</p>
              <p className="text-sm text-neutral-secondary col-span-3">
                {getScheduleByDay("Wednesday", dataSchedule ?? null)
                  .split("\n")
                  .map(function (item: any, idx: any) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </p>
            </div>

            <div className="w-full bg-[#f5fbfe] py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
              <p className="text-sm col-span-2">Kamis</p>
              <p className="text-sm text-neutral-secondary col-span-3">
                {getScheduleByDay("Thursday", dataSchedule ?? null)
                  .split("\n")
                  .map(function (item: any, idx: any) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </p>
            </div>

            <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
              <p className="text-sm col-span-2">Jumat</p>
              <p className="text-sm text-neutral-secondary col-span-3">
                {getScheduleByDay("Friday", dataSchedule ?? null)
                  .split("\n")
                  .map(function (item: any, idx: any) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </p>
            </div>

            <div className="w-full bg-[#f5fbfe] py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
              <p className="text-sm col-span-2">Sabtu</p>
              <p className="text-sm text-neutral-secondary col-span-3">
                {getScheduleByDay("Saturday", dataSchedule ?? null)
                  .split("\n")
                  .map(function (item: any, idx: any) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </p>
            </div>

            <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4 rounded-b-lg">
              <p className="text-sm col-span-2">Minggu</p>
              <p className="text-sm text-neutral-secondary col-span-3">
                {getScheduleByDay("Sunday", dataSchedule ?? null)
                  .split("\n")
                  .map(function (item: any, idx: any) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
              </p>
            </div>
          </div>
        </div>

        <div
          className="flex items-center justify-between border-b p-4 cursor-pointer hover:bg-blue-background/90"
          onClick={() => setFormChoosed("notice_period")}
        >
          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">Periode Peringatan</p>
            <p className="text-sm">
              Klien Anda akan memesan slot waktu Anda lebih awal dari waktu yang
              Anda tentukan
            </p>
          </div>
          <ArrowRight2 size="20" className="neutral-black min-w-[16px]" />
        </div>

        <div
          className="flex items-center justify-between border-b p-4 cursor-pointer hover:bg-blue-background/90"
          onClick={() => setFormChoosed("integrate")}
        >
          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">Integrasi Google Kalender</p>
            <p className="text-sm">
              Ohana akan memeriksa kalender Anda secara otomatis untuk
              mengetahui waktu sibuk dan menambahkan acara baru sesuai jadwal.
            </p>
          </div>
          <ArrowRight2 size="20" className="neutral-black min-w-[16px]" />
        </div>
      </div>
      <div className="col-span-4 flex flex-row flex-wrap items-center justify-center h-auto min-h-full bg-custom-neutral">
        {formChoosed === "time_zone" ? (
          <TimeZone
            data={dataTimeZone}
            choosedData={cachedTimezone}
            onSave={(newData) => {
              setCachedTimezone(newData);
              setFormChoosed("none");
            }}
          />
        ) : formChoosed === "date_range" ? (
          <DateRange
            data={dataDateRange}
            choosedData={dataSchedule.date_range}
            onSave={(newData) => {
              dataSchedule.date_range = newData;
              setFormChoosed("none");
              onSave();
            }}
          />
        ) : formChoosed === "notice_period" ? (
          <NoticePeriod
            data={dataNoticePeriod}
            choosedData={dataSchedule.notice_period}
            onSave={(newData) => {
              dataSchedule.notice_period = newData;
              setFormChoosed("none");
              onSave();
            }}
          />
        ) : formChoosed === "integrate" ? (
          <CalendarIntegration
            code={profile.member_code}
            onSave={() => {
              setFormChoosed("none");
              onSave();
            }}
          />
        ) : formChoosed === "schedule" ? (
          <SettingSchedule
            schedule={dataSchedule.schedule_day}
            onSave={(data) => {
              dataSchedule.schedule_day = data;
              setFormChoosed("none");
              onSave();
            }}
          />
        ) : (
          <NoContent />
        )}
      </div>
    </div>
  );
};

export default Availability;
