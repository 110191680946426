import { MenuItem, Select } from "@mui/material";
import ImgHeaderRegister from "assets/images/img_header_register.png";
import { InputText } from "components/cinput";
import config from "helpers/config";
import { setNameCookies, setRoleCookies } from "helpers/localStorage";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getBirthyear,
  getCountry,
  getEducationLevel,
  getGenderWithKey,
  getMaritalWithKey,
  getReligion,
} from "services/global";
import { updateProfile } from "services/profile";
import { CapitalizeFirstLetter } from "utils";

export default function Register() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [birthyear, setBirthyear] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [marital, setMarital] = useState("");

  const [countries, setCountries] = useState<any[]>([]);
  const [birthyears, setBirthyears] = useState<any[]>([]);
  const [genders, setGenders] = useState<any[]>([]);
  const [educationLevels, setEducationLevels] = useState<any[]>([]);
  const [religions, setReligions] = useState<any[]>([]);
  const [maritals, setMaritals] = useState<any[]>([]);

  useEffect(() => {
    getCountry().then((val) => {
      setCountries(val.data ?? []);
    });

    getBirthyear().then((val) => {
      setBirthyears(val ?? []);
    });

    getGenderWithKey({ lang: "id" }).then((val) => {
      setGenders(val ?? []);
    });

    getEducationLevel({ lang: "id" }).then((val) => {
      setEducationLevels(val.data);
    });

    getReligion({ lang: "id" }).then((val) => {
      setReligions(val.data);
    });

    getMaritalWithKey({ lang: "id" }).then((val) => {
      setMaritals(val ?? []);
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const isDisabled =
    name === "" ||
    phone === "" ||
    country === "" ||
    birthyear === "" ||
    gender === "" ||
    religion === "" ||
    educationLevel === "" ||
    marital === "";

  const onSubmit = async () => {
    setIsLoading(true);
    updateProfile({
      username: name,
      phone: phone,
      gender_key: gender,
      birthyear: birthyear,
      marital: marital,
      country: country,
      religion_id: religion,
      education_level_id: educationLevel,
      is_register: true,
    })
      .then((res) => {
        setIsLoading(false);
        setNameCookies(name);
        setRoleCookies("member");
        window.location.replace(config.LANDING_URL);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  return (
    <div className="w-full flex flex-col items-center m-auto text-center pb-24">
      <img src={ImgHeaderRegister} alt="" className="w-full object-cover" />
      <div className="w-96 ">
        <p className="text-xl font-medium mt-12">
          Pertama, mari kita isi nama Anda
        </p>
        <p className="text-base text-text-grey mt-2">
          Bagaimana Anda ingin dipanggil?
        </p>
        <div className="mt-8">
          <InputText
            placeholder="Nama Anda"
            onChange={(val) => {
              setName(val);
            }}
            defaultValue={name}
            type="text"
            id="name"
          ></InputText>
        </div>

        <div className="mt-4 text-start">
          <div className="mb-1">
            <label
              className="text-sm font-medium text-text-grey"
              htmlFor="phone"
            >
              Phone Number*
            </label>
          </div>
          <InputText
            placeholder="Your phone number"
            onChange={(val) => {
              setPhone(val);
            }}
            defaultValue={phone}
            type="tel"
            id="phone"
          ></InputText>
        </div>

        <div className="mt-4 text-start">
          <div className="mb-1">
            <label
              className="text-sm font-medium text-text-grey"
              htmlFor="phone"
            >
              Negara*
            </label>
          </div>
          <Select
            displayEmpty
            value={country}
            onChange={(e) => {
              setCountry(e.target.value as string);
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              marginTop: "8px",
              width: "100%",
              fontSize: 14,
              borderRadius: 10,
            }}
          >
            <MenuItem value={""}>
              <em className="text-slate-400">Negara</em>
            </MenuItem>
            {countries.map((item: any) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="mt-4 text-start">
          <div className="mb-1">
            <label
              className="text-sm font-medium text-text-grey"
              htmlFor="phone"
            >
              Tahun Lahir*
            </label>
          </div>
          <Select
            displayEmpty
            value={birthyear}
            onChange={(e) => {
              setBirthyear(e.target.value as string);
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              marginTop: "8px",
              width: "100%",
              fontSize: 16,
              borderRadius: 10,
            }}
          >
            <MenuItem value={""}>
              <em className="text-slate-400">Tahun lahir</em>
            </MenuItem>
            {birthyears.map((item: any, index: any) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="mt-4 text-start">
          <div className="mb-1">
            <label
              className="text-sm font-medium text-text-grey"
              htmlFor="phone"
            >
              Jenis Kelamin*
            </label>
          </div>
          <Select
            displayEmpty
            value={gender}
            onChange={(e) => {
              setGender(e.target.value as string);
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              marginTop: "8px",
              width: "100%",
              fontSize: 16,
              borderRadius: 10,
            }}
          >
            <MenuItem value={""}>
              <em className="text-slate-400">Jenis Kelamin</em>
            </MenuItem>
            {genders.map((item: any) => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="mt-4 text-start">
          <div className="mb-1">
            <label
              className="text-sm font-medium text-text-grey"
              htmlFor="phone"
            >
              Status Pernikahan*
            </label>
          </div>
          <Select
            displayEmpty
            value={marital}
            onChange={(e) => {
              setMarital(e.target.value as string);
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              marginTop: "8px",
              width: "100%",
              fontSize: 16,
              borderRadius: 10,
            }}
          >
            <MenuItem value={""}>
              <em className="text-slate-400">Marital Status</em>
            </MenuItem>
            {maritals.map((item: any) => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="mt-4 text-start">
          <div className="mb-1">
            <label
              className="text-sm font-medium text-text-grey"
              htmlFor="phone"
            >
              Tingkat Pendidikan*
            </label>
          </div>
          <Select
            displayEmpty
            value={educationLevel}
            onChange={(e) => {
              setEducationLevel(e.target.value);
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              marginTop: "8px",
              width: "100%",
              fontSize: 16,
              borderRadius: 10,
            }}
          >
            <MenuItem value={""}>
              <em className="text-slate-400">Tingkat Pendidikan</em>
            </MenuItem>
            {educationLevels.map((item: any, index: any) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="mt-4 text-start">
          <div className="mb-1">
            <label
              className="text-sm font-medium text-text-grey"
              htmlFor="religion"
            >
              Agama*
            </label>
          </div>
          <Select
            displayEmpty
            value={religion}
            onChange={(e) => {
              setReligion(e.target.value);
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              marginTop: "8px",
              width: "100%",
              fontSize: 16,
              borderRadius: 10,
            }}
          >
            <MenuItem value={""}>
              <em className="text-slate-400">Agama</em>
            </MenuItem>
            {religions.map((item: any, index: any) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <button
          type="button"
          className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-12 mt-4 disabled:bg-slate-100"
          onClick={onSubmit}
          disabled={isDisabled}
        >
          <strong>{isLoading ? "Menyimpan..." : "Simpan"}</strong>
          {isLoading ? (
            <div
              className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            ></div>
          ) : null}
        </button>
      </div>
    </div>
  );
}
