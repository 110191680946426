import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { getTokenAuth } from "helpers/localStorage";
import { getDetailTransaction, getTransaction } from "services/transaction";
import moment from "moment";
import DetailTransaction from "./content/detail_transaction";
import { formatRupiah } from "utils";
import { ReactComponent as IconNoSession } from "assets/icons/ic_no_session.svg";
import { useParams } from "react-router-dom";

export default function Transaction() {
  const { id } = useParams();

  useEffect(() => {
    if (id !== undefined && id !== "") fetchDetailTransaction(id);
  }, [id]);

  const onGetTransaction = () => {
    if (getTokenAuth() === null) {
      return () => null;
    }
    return () => {
      try {
        return getTransaction({ lang: "id", keyword: "", limit: "10" }).then(
          (res) => res.data
        );
      } catch (error) {
        alert(error);
      }
    };
  };

  const fetchDetailTransaction = (_code) => {
    getDetailTransaction({ code: _code })
      .then((val) => {
        setDetailTransaction(val.data);
      })
      .catch((err) => alert(err));
  };

  const queryTransaction = useQuery("getTransaction", onGetTransaction());
  const dataTransaction = queryTransaction.data;

  const [dataDetailTransaction, setDetailTransaction] = useState();

  return (
    <div className="grid grid-cols-1 md:grid-cols-6 w-full h-auto min-h-screen">
      <div className="col-span-2 h-auto min-h-full p-6 md:border-r md:border-r-grey-500 border-b border-b-grey-500">
        <h1 className="text-xl font-semibold">Transaksi</h1>
        <div className="divide-y divide-custom-white">
          {dataTransaction !== undefined ? (
            dataTransaction !== null ? (
              dataTransaction.map((item, i) => (
                <div
                  key={item.order_code}
                  className="py-3"
                  onClick={() => {
                    setDetailTransaction(item);
                  }}
                >
                  <div className="flex items-start justify-between mb-2">
                    <p className="font-medium text-sm">
                      {"Konsultasi (" + item.title + ")"}
                    </p>
                    <p className="font-medium text-xs min-w-[100px] text-end text-neutral-secondary">
                      {moment(item.created_date).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="font-medium text-base text-brand-dark">
                      {formatRupiah(item.amount)}
                    </p>
                    <div
                      className={
                        "rounded-md items-center " +
                        (item.order_refund !== null
                          ? "bg-transaction-refund"
                          : item.status === "completed"
                          ? "bg-transaction-success"
                          : item.status === "failed" ||
                            item.status === "cancelled" ||
                            item.status === "cancelled_book"
                          ? "bg-transaction-cancel"
                          : item.status === "expired"
                          ? "bg-transaction-expired"
                          : "bg-transaction-pending")
                      }
                    >
                      <p
                        className={
                          "font-normal px-2 py-1 text-xs text-center text-neutral-secondary " +
                          (item.order_refund !== null
                            ? "text-transaction-refund-text"
                            : item.status === "completed"
                            ? "text-transaction-success-text"
                            : item.status === "failed" ||
                              item.status === "cancelled" ||
                              item.status === "cancelled_book"
                            ? "text-transaction-cancel-text"
                            : item.status === "expired"
                            ? "text-transaction-expired-text"
                            : "text-transaction-pending-text")
                        }
                      >
                        {item.order_refund !== null
                          ? "Permintaan Pengembalian Dana"
                          : item.status === "completed"
                          ? "Pembayaran Berhasil"
                          : item.status === "failed" ||
                            item.status === "cancelled" ||
                            item.status === "cancelled_book"
                          ? "Pembayaran Dibatalkan"
                          : item.status === "expired"
                          ? "Pembayaran Kedaluwarsa"
                          : "Menunggu Pembayaran"}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center text-center mt-[80px] mb-[80px]">
                <div className="">
                  <IconNoSession />
                  <p className="text-text-grey text-xs">Belum Ada Transaksi</p>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
      <div className="col-span-4 h-auto min-h-full bg-white">
        {dataDetailTransaction !== undefined && dataDetailTransaction !== "" ? (
          <DetailTransaction
            dataReservation={dataDetailTransaction}
            onChange={() => {}}
          />
        ) : (
          <div className="p-6">
            <div className="w-full">
              <h1 className="text-xl font-semibold">Info Transaksi</h1>
            </div>
            <div className="w-full flex flex-col items-center justify-center text-center mt-[150px] mb-[150px]">
              <IconNoSession />
              <p className="text-text-grey text-xs">Belum Ada Transaksi</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
