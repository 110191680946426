import React from "react";
import { ModalSimple } from "components/modal/modal_simple";
import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import { ReactComponent as IconShare } from "assets/icons/ic_share.svg";
import { ReactComponent as IconCopy } from "assets/icons/ic_copy.svg";
import { ReactComponent as ImgReferClient } from "assets/images/img_refer_client.svg";
import { toast } from "react-toastify";
import config from "helpers/config";
import { getUserdata } from "helpers/localStorage";

export default function ModalRefer(props: {
  isShow: boolean;
  toggle: () => void;
  onSubmit: () => void;
}) {
  return (
    <ModalSimple isShown={props.isShow} title="" hide={props.toggle}>
      <>
        <DialogContent>
          {/* CONTENT */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ImgReferClient className="my-6" />
            <p className="text-lg font-semibold">Undang Klien Anda</p>
            <p className="text-sm text-neutral-secondary">
              Bagikan tautan dibawah dengan klien Anda
            </p>
            <div className="border rounded-full py-2 px-5 w-[300px] flex items-center justify-between mt-4">
              <p>{config.LANDING_URL}</p>
              <IconCopy
                onClick={() => {
                  navigator.clipboard.writeText(
                    config.LANDING_URL +
                      "/booking/profile/" +
                      getUserdata().memberCode
                  );
                  toast.success("Tautan berhasil disalin");
                }}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "4px 24px !important" }}>
          <button
            type="button"
            className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full h-12 mb-8 disabled:bg-slate-300 mx-auto w-[300px]"
            onClick={() => props.onSubmit()}
          >
            <IconShare className="mr-3" />
            <p>Share</p>
          </button>
        </DialogActions>
      </>
    </ModalSimple>
  );
}
