import { ReactComponent as IconChangePasswordSuccess } from "assets/icons/ic_change_password_success.svg";
import { useEffect, useState } from "react";
import {
  confirmNewPassword,
  reqForgotPassword,
  verifyPinForgotPassword,
} from "services/profile";
import { requestOTP } from "services/auth";
import { toast } from "react-toastify";
import { CapitalizeFirstLetter } from "utils";
import ShowHidePassword from "components/showHidePassword";
import OtpInput from "components/cotp";

export default function ForgotSection(props: { email; memberCode }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isBtnPasswordDisabled, setBtnPasswordDisabled] = useState(true);
  const [isBtnVerifyDisabled, setBtnVerifyDisabled] = useState(true);

  const onChangeOTP = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    setBtnVerifyDisabled(otp.trim().length < 4);
  }, [otp]);

  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);

  const onSendMeCode = async () => {
    setIsLoading(true);
    reqForgotPassword({
      email: props.email,
    })
      .then((res) => {
        setIsLoading(false);
        setShowState("otp");
        setCanResend(false);
        setTimeLeft(30);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onSubmitPassword = async () => {
    setIsLoadingPassword(true);
    confirmNewPassword({
      pin: otp,
      memberCode: props.memberCode,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    })
      .then((res) => {
        requestOTP().then((res) => {
          setIsLoadingPassword(false);
          setShowState("success");
        });
      })
      .catch((err) => {
        setIsLoadingPassword(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onResend = async () => {
    setIsLoadingResend(true);
    reqForgotPassword({
      email: props.email,
    })
      .then((res) => {
        requestOTP().then((res) => {
          setIsLoadingResend(false);
          setTimeLeft(30);
          setCanResend(false);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onVerify = async () => {
    setIsLoadingVerify(true);
    verifyPinForgotPassword({
      email: props.email,
      pin: otp,
    })
      .then((res) => {
        setIsLoadingVerify(false);
        setShowState("password");
      })
      .catch((err) => {
        setIsLoadingVerify(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const [showState, setShowState] = useState("");

  return (
    <div className="w-full m-8 bg-white rounded-lg ">
      {showState === "otp" ? (
        <div className="m-auto text-center py-8">
          <p className="text-lg font-semibold">Kode Verifikasi</p>
          <p className="text-sm text-text-grey mt-4">
            Masukkan kode 4 Digit untuk memverifikasi email Anda
          </p>
          <p className="text-sm text-text-grey">
            yang telah dikirim ke <b className="font-medium">{props.email}</b>
          </p>

          <div className="flex justify-center my-5">
            <OtpInput value={otp} valueLength={4} onChange={onChangeOTP} />
          </div>
          <div className="mt-6">
            {isLoadingResend ? (
              <div className="flex justify-center">
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              </div>
            ) : canResend ? (
              <div className="flex justify-center">
                <p className="text-sm text-text-grey">Tidak menerima kode?</p>
                <p
                  className="text-sm font-medium text-text-dark-blue ml-1 cursor-pointer"
                  onClick={() => {
                    onResend();
                  }}
                >
                  Kirim Ulang Kode
                </p>
              </div>
            ) : (
              <p className="text-sm text-text-grey">
                {"Harap tunggu " +
                  timeLeft +
                  " detik untuk mengirim ulang kode"}
              </p>
            )}
          </div>
          <div className="w-full flex justify-center mt-4">
            <button
              type="button"
              disabled={isBtnVerifyDisabled}
              className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-80 h-12 disabled:bg-slate-300"
              onClick={() => onVerify()}
            >
              <strong>
                {isLoadingVerify ? "Memverifikasi..." : "Verifikasi"}
              </strong>
              {isLoadingVerify ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
          </div>
        </div>
      ) : showState === "success" ? (
        <div className="m-auto text-center py-8">
          <div className="flex justify-center">
            <IconChangePasswordSuccess />
          </div>
          <p className="text-lg font-medium">
            Password Anda telah berhasil
            <br />
            diperbarui!
          </p>
        </div>
      ) : showState === "password" ? (
        <div className="m-auto text-center py-8">
          <p className="text-lg font-semibold">Ubah Password</p>
          <p className="text-base text-text-grey">
            Password baru Anda harus berbeda dengan password yang digunakan
            sebelumnya
          </p>

          <div className="text-start max-w-[400px] m-auto">
            <div className="my-4"></div>
            <ShowHidePassword
              name={newPassword}
              defaultValue={newPassword}
              placeholder="Password Baru Anda"
              onChange={(val) => {
                setNewPassword(val);
                setBtnPasswordDisabled(val === "" || confirmPassword === "");
              }}
            ></ShowHidePassword>
            <div className="my-4"></div>
            <ShowHidePassword
              name={confirmPassword}
              defaultValue={confirmPassword}
              placeholder="Konfirmasi Password Baru Anda"
              onChange={(val) => {
                setConfirmPassword(val);
                setBtnPasswordDisabled(val === "" || newPassword === "");
              }}
            ></ShowHidePassword>
            <button
              type="button"
              className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full h-12 w-full mt-6 disabled:bg-slate-300"
              disabled={isBtnPasswordDisabled}
              onClick={() => onSubmitPassword()}
            >
              <strong>
                {isLoadingPassword
                  ? "Mengatur Ulang..."
                  : "Atur Ulang Password"}
              </strong>
              {isLoadingPassword ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
          </div>
        </div>
      ) : (
        <div className="m-auto text-center py-8 max-w-[320px]">
          <p className="text-lg font-semibold">Lupa Password</p>
          <p className="text-sm text-text-grey mt-4">
            Email Anda yang terdaftar adalah{" "}
            <span className="font-medium">{props.email}</span>. Kami akan
            mengirimkan kode untuk melanjutkan lupa password.
          </p>
          <button
            type="button"
            className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-full h-12 mt-4"
            onClick={() => onSendMeCode()}
          >
            <strong>{isLoading ? "Mengirim..." : "Kirimi saya kode"}</strong>
            {isLoading ? (
              <div
                className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              ></div>
            ) : null}
          </button>
        </div>
      )}
    </div>
  );
}
