/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircle } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { getScheduleMonthlyV2, getSchedulesDaily } from "services/session";
import moment from "moment";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

export default function RescheduleSection(props: {
  code;
  psychologCode;
  formatCall;
  onClose: () => void;
  onReschedule: (scheduleCode: any) => void;
}) {
  const [calendarDate, setCalendarDate] = useState(new Date());

  const [value, setValue] = useState(new Date());
  const [scheduleDetailCode, setScheduleDetailCode] = useState("");
  const [scheduleDetail, setScheduleDetail] = useState<any>();

  const onFetchScheduleDaily = () => {
    return () =>
      getSchedulesDaily({
        memberCode: props.psychologCode,
        status: "active",
        date: moment(value.toISOString()).format("YYYY-MM-DD"),
      }).then((val) => {
        return val.data;
      });
  };

  const onFetchScheduleMonthly = () => {
    return getScheduleMonthlyV2({
      psychologCode: props.psychologCode,
      formatCall: props.formatCall,
      year: calendarDate.getFullYear(),
      month: calendarDate.getMonth() + 1,
    })
      .then((val) => val.data)
      .catch((err) => toast.error(err));
  };

  const queryScheduleMonthly = useQuery(
    "fetchScheduleMonthly",
    onFetchScheduleMonthly
  );

  const querySchedule = useQuery("fetchSchedule", onFetchScheduleDaily());
  const schedule = querySchedule.data;

  const scheduleMonthly = queryScheduleMonthly.data ?? [];

  useEffect(() => {
    queryScheduleMonthly.refetch();
  }, [calendarDate]);

  useEffect(() => {
    setScheduleDetailCode("");
    querySchedule.refetch();
  }, [value]);

  function ServerDay(
    props: PickersDayProps<Date> & {
      availables?: any[];
      onChange: (val: Date) => void;
    }
  ) {
    const { availables = [], day, outsideCurrentMonth, ...other } = props;

    const indexAvailable = availables.findIndex(
      (val) => val.date === dayjs(day).format("YYYY-MM-DD")
    );
    const isSelected = !props.outsideCurrentMonth && indexAvailable !== -1;

    return (
      <div
        className={`${
          isSelected ? "w-10 h-10 bg-blue-background rounded-full" : ""
        }`}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          disabled={!isSelected}
          onClick={() => props.onChange(availables[indexAvailable].date)}
        />
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
        <div
          className="w-full h-full bg-black/40"
          onClick={() => {
            props.onClose();
          }}
        ></div>
        <div className="absolute w-full my-6 mx-auto md:max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
            <div className="px-10 py-6 w-full">
              <div className="flex place-self-stretch items-start justify-between min-w-20">
                <h3 className="text-base font-semibold">
                  Penjadwalan Ulang Sesi
                </h3>
                <button
                  className="bg-transparent border-0 text-black float-right"
                  onClick={() => props.onClose()}
                >
                  <CloseCircle size="24" color="#121122" />
                </button>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-7  mt-4 gap-4">
                <div className="sm:col-span-3 flex flex-col items-start">
                  <h1 className="text-base font-medium text-text-black mb-2">
                    Tanggal Penjadwalan Ulang
                  </h1>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      onMonthChange={(val) => setCalendarDate(val as Date)}
                      onYearChange={(val) => setCalendarDate(val as Date)}
                      renderLoading={() => <DayCalendarSkeleton />}
                      slots={{ day: ServerDay } as any}
                      sx={{
                        width: 1,
                        mb: 3,
                        "& .MuiPickersFadeTransitionGroup-root ": {
                          width: 1,
                          display: "",
                        },
                        "& .MuiDayCalendar-header ": {
                          width: 1,
                          justifyContent: "space-between",
                        },
                        "& .MuiPickersSlideTransition-root ": {
                          width: 1,
                          display: "",
                        },
                        "& .MuiDayCalendar-weekContainer ": {
                          width: 1,
                          justifyContent: "space-between",
                        },
                      }}
                      slotProps={{
                        day: {
                          availables: scheduleMonthly,
                          onChange: (val: string) => setValue(new Date(val)),
                        } as any,
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="sm:col-span-4 flex flex-col items-start">
                  <h1 className="text-base font-medium text-text-black mb-2">
                    Waktu Penjadwalan Ulang
                  </h1>
                  {(schedule ?? []).map((val: any, i: number) => {
                    return (
                      <div
                        className="w-full flex flex-col items-start mb-2"
                        key={i}
                      >
                        <div className="mb-2 text-neutral-secondary text-xs md:text-sm ">
                          {val.period}
                        </div>
                        <div className="w-full grid grid-cols-5 md:grid-cols-2 gap-3">
                          {val.details.map((item: any, j: number) => {
                            return (
                              <div
                                key={`${i}-${j}`}
                                className={`py-2 text-center px-2 text-xs md:text-sm border rounded-lg cursor-pointer ${
                                  item.schedule_detail_code ===
                                  scheduleDetailCode
                                    ? "border-black-app bg-blue-background"
                                    : ""
                                }`}
                                onClick={() => {
                                  setScheduleDetail(item);
                                  setScheduleDetailCode(
                                    item.schedule_detail_code
                                  );
                                }}
                              >
                                {moment(item.time_from, "HH:mm").format(
                                  "hh:mm A"
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <button
                type="button"
                className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full px-8 h-12 mt-4 disabled:bg-slate-400 disabled:cursor-not-allowed"
                disabled={scheduleDetailCode === ""}
                onClick={() => props.onReschedule(scheduleDetail)}
              >
                <p className="font-medium">Penjadwalan Ulang</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
