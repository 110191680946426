/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import { CardAppointment } from "components/CardAppointment";
import { ModalSimple } from "components/modal/modal_simple";
import moment from "moment";
import { User, Video } from "iconsax-react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSpecialistRates } from "services/profile";
import {
  getAvailabilityFormatCall,
  getAvailabilityLocationV2,
  getScheduleMonthlyV2,
  getSchedulesV2,
} from "services/schedule";
import { formatRupiah } from "utils";
import { CardLocation } from "components/CardLocation";
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import id from "date-fns/locale/id";
import dayjs from "dayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const IN_PERSON_CHILD = "in_person_child";
const IN_PERSON_COUPLE = "in_person_couple";
const IN_PERSON_FAMILY = "in_person_family";
const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
const IN_PERSON_INDIVIDUAL = "in_person_individual";
const IN_PERSON_SI_THERAPY = "in_person_sensory_integration_therapy";
const IN_PERSON_SPEECH_THERAPY = "in_person_speech_therapy";
const ONLINE_CALL_COUPLE = "online_call_couple";
const ONLINE_CALL_INDIVIDUAL = "online_call_individual";

export default function ModalCreateSession(props: {
  hide: () => void;
  isShow: boolean;
  profile: any;
  onSubmit: (val: any) => void;
}) {
  const [calendarDate, setCalendarDate] = useState(new Date());

  const [value, setValue] = useState(new Date());
  const [appointmentType, setAppointmentType] = useState(
    "in_person_individual"
  );
  const [locationCode, setLocationCode] = useState("");
  const [location, setLocation] = useState<any>();
  const [scheduleDetail, setScheduleDetail] = useState<any>();
  const [scheduleDetailCode, setScheduleDetailCode] = useState("");

  const isButtonEnabled = appointmentType.includes("online")
    ? scheduleDetailCode
    : scheduleDetailCode && locationCode;

  const [schedule, setSchedule] = useState<any>();
  const onFetchSchedule = () => {
    getSchedulesV2({
      memberCode: props.profile.member_code,
      status: "active",
      date: moment(value.toISOString()).format("YYYY-MM-DD"),
      formatCall: appointmentType,
      locationCode: locationCode,
    }).then((val) => {
      setSchedule(val.data);
    });
  };

  const onFetchAvailabilityFormatCall = () => {
    return () =>
      getAvailabilityFormatCall({
        memberCode: props.profile.member_code,
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1,
      }).then((val) => {
        return val.data;
      });
  };

  const queryFormatCall = useQuery(
    "getAvailabilityFormatCall",
    onFetchAvailabilityFormatCall()
  );
  const formatCalls = queryFormatCall.data;

  const [scheduleMonthly, setScheduleMonthly] = useState<any>();
  const fetchScheduleMonthly = () => {
    getScheduleMonthlyV2({
      memberCode: props.profile.member_code,
      formatCall: appointmentType,
      locationCode: locationCode,
      year: calendarDate.getFullYear(),
      month: calendarDate.getMonth() + 1,
    }).then((val) => {
      setScheduleMonthly(val.data);
    });
    // .catch((err) => toast.error(err));
  };

  const isDisabled = scheduleDetailCode === "";

  const getPrice = (appointmentType: string) => {
    const price = formatRupiah(
      profileRates.data?.rates?.find(
        (_data: { format_call: string }) =>
          _data.format_call === appointmentType
      )?.price
    );
    // console.log(appointmentType + " : " + price);
    return price.replace(/\u00A0/, " ");
  };

  const getPeriod = (period: string) => {
    if (period === "Morning") {
      return "Pagi";
    } else if (period === "Afternoon") {
      return "Siang";
    } else if (period === "Evening") {
      return "Sore";
    } else {
      return "Malam";
    }
  };

  const onFetchRates = () => {
    return () =>
      getSpecialistRates({
        memberCode: props.profile.member_code,
        lang: "id",
      }).then((val) => {
        return val.data;
      });
  };

  const profileRates = useQuery("getSpecialistRates", onFetchRates());

  const onFetchLocation = () => {
    return () =>
      getAvailabilityLocationV2({
        memberCode: props.profile.member_code,
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1,
      }).then((val) => {
        return val.data;
      });
  };

  const scheduleLocation = useQuery("getLocation", onFetchLocation());

  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (appointmentType !== "" && locationCode !== "" && showCalendar)
      fetchScheduleMonthly();
    if (appointmentType.includes("online") && showCalendar)
      fetchScheduleMonthly();
  }, [calendarDate, appointmentType, locationCode]);

  useEffect(() => {
    queryFormatCall.refetch();
  }, [calendarDate]);

  useEffect(() => {
    setScheduleDetailCode("");
    if (appointmentType !== "" && locationCode !== "" && showCalendar)
      onFetchSchedule();
    if (appointmentType.includes("online") && showCalendar) onFetchSchedule();
  }, [value]);

  useEffect(() => {
    if (!props.isShow) {
      return;
    }
  }, [props.isShow]);

  const onSubmit = () => {
    props.onSubmit({
      format_call: appointmentType,
      date: moment(value.toISOString()).format("YYYY-MM-DD"),
      schedule: scheduleDetail,
      location: location,
    });
  };

  return (
    <>
      <ModalSimple
        isShown={props.isShow}
        title="Tambah Sesi"
        hide={props.hide}
        width="840px"
      >
        <>
          <DialogContent>
            <FormControl className="w-full">
              <div className="p-8 bg-white rounded-xl lg:basis-7/12">
                <p>Pilih Tipe</p>
                {formatCalls !== undefined ? (
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                    <CardAppointment
                      icon={<User className="w-5 md:w-8" variant="Bold" />}
                      title="Face to face (Individual)"
                      price={getPrice("in_person_individual")}
                      isHidden={
                        !formatCalls.in_person_individual ||
                        getPrice("in_person_individual") === "Rp 0" ||
                        getPrice("in_person_individual") === "RpNaN"
                      }
                      choosed={appointmentType === IN_PERSON_INDIVIDUAL}
                      onClick={() => setAppointmentType(IN_PERSON_INDIVIDUAL)}
                    />
                    <CardAppointment
                      icon={<User className="w-5 md:w-8" variant="Bold" />}
                      title="Face to face (Couple)"
                      price={getPrice("in_person_couple")}
                      isHidden={
                        !formatCalls.in_person_couple ||
                        getPrice("in_person_couple") === "Rp 0" ||
                        getPrice("in_person_couple") === "RpNaN"
                      }
                      choosed={appointmentType === IN_PERSON_COUPLE}
                      onClick={() => setAppointmentType(IN_PERSON_COUPLE)}
                    />
                    <CardAppointment
                      icon={<User className="w-5 md:w-8" variant="Bold" />}
                      title="Face to face (Family)"
                      price={getPrice("in_person_family")}
                      isHidden={
                        !formatCalls.in_person_family ||
                        getPrice("in_person_family") === "Rp 0" ||
                        getPrice("in_person_family") === "RpNaN"
                      }
                      choosed={appointmentType === IN_PERSON_FAMILY}
                      onClick={() => setAppointmentType(IN_PERSON_FAMILY)}
                    />
                    <CardAppointment
                      icon={<User className="w-5 md:w-8" variant="Bold" />}
                      title="Face to face (Child)"
                      price={getPrice("in_person_child")}
                      isHidden={
                        !formatCalls.in_person_child ||
                        getPrice("in_person_child") === "Rp 0" ||
                        getPrice("in_person_child") === "RpNaN"
                      }
                      choosed={appointmentType === IN_PERSON_CHILD}
                      onClick={() => setAppointmentType(IN_PERSON_CHILD)}
                    />
                    <CardAppointment
                      icon={<User className="w-5 md:w-8" variant="Bold" />}
                      title="Face to face (Hypnotherapy)"
                      price={getPrice("in_person_hypnotherapy")}
                      isHidden={
                        !formatCalls.in_person_hypnotherapy ||
                        getPrice("in_person_hypnotherapy") === "Rp 0" ||
                        getPrice("in_person_hypnotherapy") === "RpNaN"
                      }
                      choosed={appointmentType === IN_PERSON_HYPNOTHERAPY}
                      onClick={() => setAppointmentType(IN_PERSON_HYPNOTHERAPY)}
                    />
                    <CardAppointment
                      icon={<User className="w-5 md:w-8" variant="Bold" />}
                      title="Face to face (Speech Therapy)"
                      price={getPrice("in_person_speech_therapy")}
                      isHidden={
                        !formatCalls.in_person_speech_therapy ||
                        getPrice("in_person_speech_therapy") === "Rp 0" ||
                        getPrice("in_person_speech_therapy") === "RpNaN"
                      }
                      choosed={appointmentType === IN_PERSON_SPEECH_THERAPY}
                      onClick={() =>
                        setAppointmentType(IN_PERSON_SPEECH_THERAPY)
                      }
                    />
                    <CardAppointment
                      icon={<User className="w-5 md:w-8" variant="Bold" />}
                      title="Face to face (SI Therapy)"
                      price={getPrice("in_person_sensory_integration_therapy")}
                      isHidden={
                        !formatCalls.in_person_sensory_integration_therapy ||
                        getPrice("in_person_sensory_integration_therapy") ===
                          "Rp 0" ||
                        getPrice("in_person_sensory_integration_therapy") ===
                          "RpNaN"
                      }
                      choosed={appointmentType === IN_PERSON_SI_THERAPY}
                      onClick={() => setAppointmentType(IN_PERSON_SI_THERAPY)}
                    />
                    <CardAppointment
                      icon={<Video className="w-5 md:w-8" variant="Bold" />}
                      title="Online Call (Individual)"
                      price={getPrice("online_call_individual")}
                      isHidden={
                        !formatCalls.online_call_individual ||
                        getPrice("online_call_individual") === "Rp 0" ||
                        getPrice("online_call_individual") === "RpNaN"
                      }
                      choosed={appointmentType === ONLINE_CALL_INDIVIDUAL}
                      onClick={() => {
                        setLocationCode("");
                        setAppointmentType(ONLINE_CALL_INDIVIDUAL);
                        setCalendarDate(new Date());
                        setValue(new Date());
                        setSchedule([]);
                        setShowCalendar(true);
                      }}
                    />
                    <CardAppointment
                      icon={<Video className="w-5 md:w-8" variant="Bold" />}
                      title="Online Call (Couple)"
                      price={getPrice("online_call_couple")}
                      isHidden={
                        !formatCalls.online_call_couple ||
                        getPrice("online_call_couple") === "Rp 0" ||
                        getPrice("online_call_couple") === "RpNaN"
                      }
                      choosed={appointmentType === ONLINE_CALL_COUPLE}
                      onClick={() => {
                        setLocationCode("");
                        setAppointmentType(ONLINE_CALL_COUPLE);
                        setCalendarDate(new Date());
                        setValue(new Date());
                        setSchedule([]);
                        setShowCalendar(true);
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <p>Pilih Lokasi</p>
                <div>
                  {appointmentType.includes("online") ? (
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                      <CardLocation
                        title="Online"
                        isOnline={true}
                        choosed={true}
                        onClick={() => {
                          setLocationCode("");
                          setValue(new Date());
                        }}
                      />
                    </div>
                  ) : scheduleLocation.data ? (
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                      {scheduleLocation.data.locations[appointmentType]?.map(
                        (val: any) => {
                          return (
                            <CardLocation
                              key={val.name}
                              title={val.name}
                              choosed={locationCode === val.location_code}
                              onClick={() => {
                                setValue(new Date());
                                setLocation(val);
                                setLocationCode(val.location_code);
                                setCalendarDate(new Date());
                                setSchedule([]);
                                setShowCalendar(true);
                              }}
                            />
                          );
                        }
                      )}
                    </div>
                  ) : null}
                </div>

                <p>Pilih Tanggal</p>
                {showCalendar ? (
                  <LocalizationProvider
                    adapterLocale={id}
                    dateAdapter={AdapterDateFns}
                  >
                    <DateCalendar
                      onMonthChange={(val) => setCalendarDate(val as Date)}
                      onYearChange={(val) => setCalendarDate(val as Date)}
                      renderLoading={() => <DayCalendarSkeleton />}
                      slots={{ day: ServerDay } as any}
                      value={value}
                      sx={{
                        width: 1,
                        mb: 3,
                        "& .MuiPickersFadeTransitionGroup-root ": {
                          width: 1,
                          display: "",
                        },
                        "& .MuiPickersCalendarHeader-root  ": {
                          padding: 0,
                          margin: 0,
                        },
                        "& .MuiDayCalendar-header ": {
                          width: 1,
                          justifyContent: "space-between",
                        },
                        "& .MuiPickersSlideTransition-root ": {
                          width: 1,
                          display: "",
                        },
                        "& .MuiDayCalendar-weekContainer ": {
                          width: 1,
                          justifyContent: "space-between",
                        },
                      }}
                      slotProps={{
                        day: {
                          availables: scheduleMonthly,
                          onChange: (val: string) => {
                            console.log(val);
                            setValue(new Date(val));
                          },
                        } as any,
                      }}
                    />
                  </LocalizationProvider>
                ) : (
                  <p className="mt-2 mb-4 text-sm">
                    Pilih Tipe Sesi dan Lokasi Terlebih dahulu
                  </p>
                )}

                <p>Pilih Jam</p>
                {(schedule ?? []).map((val: any, i: number) => {
                  return (
                    <div className="my-6" key={i}>
                      <div className="mb-4 text-neutral-secondary">
                        {getPeriod(val.period)}
                      </div>
                      <div className="grid grid-flow-row grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
                        {val.details.map((item: any, j: number) => {
                          return (
                            <div
                              key={`${i}-${j}`}
                              className={`py-2 text-center px-8 border rounded-lg cursor-pointer ${
                                item.schedule_detail_code === scheduleDetailCode
                                  ? "border-black-app bg-blue-background"
                                  : ""
                              }`}
                              onClick={() => {
                                setScheduleDetail(item);
                                setScheduleDetailCode(
                                  item.schedule_detail_code
                                );
                              }}
                            >
                              {moment(item.time_from, "HH:mm").format(
                                "hh:mm A"
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px !important" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <button
                onClick={onSubmit}
                className={`text-white bg-black hover:bg-black/60 rounded-full w-full h-12 ${
                  isButtonEnabled ? "" : "bg-neutral-200"
                }`}
                style={{ marginTop: "30px" }}
              >
                Bagikan Link Pembayaran
              </button>
            </Box>
          </DialogActions>
        </>
      </ModalSimple>
    </>
  );
}

function ServerDay(
  props: PickersDayProps<Date> & {
    availables?: any[];
    onChange: (val: Date) => void;
  }
) {
  const { availables = [], day, outsideCurrentMonth, ...other } = props;

  const indexAvailable = availables.findIndex(
    (val) => val.date === dayjs(day).format("YYYY-MM-DD")
  );
  const isSelected = !props.outsideCurrentMonth && indexAvailable !== -1;

  return (
    <div
      className={`${
        isSelected ? "w-10 h-10 bg-blue-background rounded-full" : ""
      }`}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={!isSelected}
        onClick={() => props.onChange(availables[indexAvailable].date)}
      />
    </div>
  );
}
