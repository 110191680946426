import axios from "axios";
import api from "./api";

export const authLogin = (params: { email: string; password: string }) => {
  return api.post("/v1/auths/login", params).then((val) => val.data);
};

export const authRefresh = () => {
  return api.get("/v1/auths/refresh").then((val) => val.data);
};

export const checkUserGoogle = (params: { token: string }) => {
  return axios
    .get("https://www.googleapis.com/oauth2/v3/userinfo", {
      headers: { Authorization: `Bearer ${params.token}` },
    })
    .then((res) => res.data);
};

export const authLoginSosmed = (params: {
  email: string;
  sosmed_type: string;
  sosmed_token: string;
}) => {
  return api.post("/v1/auths/login-sosmed", params).then((val) => val.data);
};

export const authRegistration = (params: {
  email: string;
  password: string;
  code?: string;
}) => {
  return api.post("/v1/auths/register", params).then((val) => val.data);
};

export const authRegistrationSosmed = (params: {
  email: string;
  sosmed_type: string;
  sosmed_token: string;
  code: string;
}) => {
  return api.post("/v1/auths/register-sosmed", params).then((val) => val.data);
};

export const requestOTP = async () => {
  return api.post("/v1/members/request-verify").then((val) => val.data);
};

export const verifyEmail = (params: { pin: string }) => {
  return api.put("/v1/members/verify-email", params).then((val) => val.data);
};

export const personalize = (params: { username: string }) => {
  return api.post("/v1/members/personalize", params).then((val) => val.data);
};
