import React, { useRef } from "react";
import { DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import { ModalSimple } from "components/modal/modal_simple";
import config from "helpers/config";
import { getSessionType } from "utils";
import moment from "moment";

export default function ModalSessionLink(props: {
  value: any;
  isShow: boolean;
  hide: () => void;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const value = props.value;

  const onSubmit = () => {
    window.navigator.clipboard.writeText(
      contentRef.current!.innerText!.replace(/\n\n/g, "\n")
    );
    toast.success("link already copied into clipboard!");
  };

  return (
    <ModalSimple
      hide={props.hide}
      isShown={props.isShow}
      title=""
      width="480px"
    >
      <DialogContent>
        <div ref={contentRef}>
          <p className="text-sm text-text-black font-semibold">
            Konfirmasi Harga Kelebihan Waktu
          </p>
          <p className="text-sm text-text-black mt-1">
            {"Nama: " + value?.psycholog_username}
          </p>
          <p className="text-sm text-text-black mt-1">
            {`Sesi: ${getSessionType(value?.format_call)}`}
          </p>
          <p className="text-sm text-text-black mt-1">
            {"Tanggal: " + value?.date}
          </p>
          <p className="text-sm text-text-black mt-1">
            {"Waktu: " + moment(value?.time_from, "HH:mm").format("HH:mm A")}
          </p>
          <p className="text-sm text-text-black mt-4">
            Gunakan tautan ini untuk menyelesaikan pembayaran Anda melalui situs
            web:{" "}
            <a
              className="text-brand-dark"
              href={getLink(value)}
              target="_blank"
              rel="noreferrer"
            >
              {getLink(value)}
            </a>
          </p>
        </div>
        <button
          onClick={onSubmit}
          className="text-white bg-black hover:bg-black/60 rounded-full w-full h-12 disabled:bg-slate-300"
          title="Salin"
          style={{ marginTop: "30px" }}
        >
          Salin teks
        </button>
      </DialogContent>
    </ModalSimple>
  );
}

function getLink(value: any) {
  return `${config.LANDING_URL}/booking/${value?.psycholog_code}/${
    value?.schedule_detail_code
  }?sessionType=${value?.format_call}&location=${value?.location_code ?? ""}`;
}
